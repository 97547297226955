import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import { useTranslation } from "react-i18next";

export const EditSales = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleEdit({
        saleId: props.row.saleid,
        date: format(date, "yyyy-MM-dd"),
        paymentToAccount: parseFloat(values.paymentToAccount),
        cashBalance: parseFloat(values.cashBalance),
        accountBalance: parseFloat(values.accountBalance),
        comment: values.comment,
      });
      event.preventDefault();
    }
    setValidated(false);
    clearValues();
  };

  const clearValues = () => {
    setDate(new Date());
    setValues({
      paymentToAccount: 0,
      cashBalance: 0,
      accountBalance: 0,
      comment: "",
    });
  };

  //date picker
  const [date, setDate] = useState();

  //other values
  const [values, setValues] = useState({
    paymentToAccount: 0,
    cashBalance: 0,
    accountBalance: 0,
    comment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    props.row?.saleid &&
      setValues({
        paymentToAccount: props.row?.paymenttoaccount,
        cashBalance: props.row?.cashbalance,
        accountBalance: props.row?.accountbalance,
        comment: props.row?.comment,
      });
    props.row?.date && setDate(new Date(props.row.date));
  }, [props.row]);
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("salespagemain.editmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagemain.title3")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="paymentToAccount"
                value={values.paymentToAccount}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagemain.title2")}:</Form.Label>
              <DatePicker
                date={date}
                onDateChange={setDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagemain.title4")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="cashBalance"
                value={values.cashBalance}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagemain.title5")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="accountBalance"
                value={values.accountBalance}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagemain.title6")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.comment")}
                name="comment"
                value={values.comment}
                onChange={handleChange}
              />
            </Form.Group>
            <Button type="submit">{t("salespagemain.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditSales;
