const initialState = {
  comments: [],
};

const commentReducer = (state = initialState, action) => {
  if (action.type === "GET_COMMENT") {
    return {
      ...state,
      comments: action.comments,
    };
  } else if (action.type === "DELETE_COMMENT") {
    return {
      ...state,
      comments: state.comments.filter(
        (item) => item.commentid !== action.commentId
      ),
    };
  } else {
    return state;
  }
};

export default commentReducer;
