import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

export const ChangePassword = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleChangePassword(values.oldPass, values.newPass);
      event.preventDefault();
    }
    setValidated(false);
    clearValues();
  };

  const clearValues = () => {
    setValues({
      oldPass: "",
      newPass: "",
    });
  };

  const [values, setValues] = useState({
    oldPass: "",
    newPass: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="validationCustom02">
          <Form.Label column lg>
            {t("userpage.oldpass")}:
          </Form.Label>
          <Col lg="12">
            <Form.Control
              size="lg"
              type="text"
              placeholder={t("placeholders.oldpassword")}
              name="oldPass"
              value={values.oldPass}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="validationCustom02">
          <Form.Label column lg>
            {t("userpage.newpass")}:
          </Form.Label>
          <Col lg="12">
            <Form.Control
              size="lg"
              type="text"
              placeholder={t("placeholders.newpassword")}
              name="newPass"
              value={values.newPass}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        <Button id="change_pass_bttn" size="lg" variant="success" type="submit">
          {t("userpage.savepassbttn")}
        </Button>
      </Form>
    </>
  );
};

export default ChangePassword;
