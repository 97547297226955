import React, { useEffect, useState } from "react";
import TableBS from "../Table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  sortSaleDetails,
  sortSale,
  getSale,
  getSaleDetails,
  deleteSale,
  deleteSaleDetails,
  addSale,
  addSaleDetails,
  editSale,
  editSaleDetails,
  getSaleTotals,
  sortSaleTotals,
  addSaleTotals,
  editSaleTotals,
  deleteSaleTotals,
} from "../../store/actions/actions";
import SaleSelector from "../../store/selectors/saleSelector";
import Button from "react-bootstrap/Button";
import DatePickerWithRange from "../DatePicker";
import { format } from "date-fns";
import { Form } from "react-bootstrap";
import DeleteSale from "./DeleteModal";
import DeleteSaleDetails from "./DeleteModalDetails";
import AddSales from "./AddModal";
import AddSalesDetail from "./AddModalDetails";
import EditSales from "./EditModal";
import EditSalesDetails from "./EditModalDetails";
import { useTranslation } from "react-i18next";
import EditSalesTotals from "./EditModalTotals";
import AddSalesTotal from "./AddModalTotals";
import DeleteSaleTotals from "./DeleteModalTotals";

export const Sales = (props) => {
  const { t } = useTranslation();

  const headersMain = [
    { title: t("salespagemain.title1"), id: "saleid" },
    { title: t("salespagemain.title2"), id: "date" },
    { title: t("salespagemain.title3"), id: "paymenttoaccount" },
    { title: t("salespagemain.title4"), id: "cashbalance" },
    { title: t("salespagemain.title5"), id: "accountbalance" },
    { title: t("salespagemain.title6"), id: "comment" },
  ];

  const headersDetails = [
    { title: t("salespagedetails.title1"), id: "saledetailsid" },
    { title: t("salespagedetails.title2"), id: "date" },
    { title: t("salespagedetails.title3"), id: "code" },
    { title: t("salespagedetails.title4"), id: "soldcount" },
    { title: t("salespagedetails.title5"), id: "amount" },
    { title: t("salespagedetails.title10"), id: "cashpaymentdescription" },
    { title: t("salespagedetails.title11"), id: "cashpaymentamount" },
    { title: t("salespagedetails.title12"), id: "accountpaymentdescription" },
    {
      title: t("salespagedetails.title13"),
      id: "accountpaymentamount",
    },
  ];

  const headersTotals = [
    { title: t("salespagetotals.title1"), id: "saleTotalId" },
    { title: t("salespagetotals.title2"), id: "date" },
    { title: t("salespagetotals.title3"), id: "totalSoldCount" },
    { title: t("salespagetotals.title4"), id: "totalAmount" },
    { title: t("salespagetotals.title5"), id: "totalCashPaymentAmount" },
    { title: t("salespagetotals.title6"), id: "totalAccountPaymentAmount" },
  ];

  //selected row
  const [row, setRow] = useState({});
  //selected row for details table
  const [rowDetails, setRowDetails] = useState({});
  //selected row for totals table
  const [rowTotals, setRowTotals] = useState({});

  //tableData
  const handleTableData = (sortedData) => {
    dispatch(sortSale(sortedData));
  };

  //tableData details
  const handleTableDataDetails = (sortedData) => {
    dispatch(sortSaleDetails(sortedData));
  };

  //tableData details
  const handleTableDataTotals = (sortedData) => {
    dispatch(sortSaleTotals(sortedData));
  };

  //edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (item) => {
    setRow(item);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);

  const [showEditModalDetails, setShowEditModalDetails] = useState(false);
  const handleShowEditModalDetails = (item) => {
    setRowDetails(item);
    setShowEditModalDetails(true);
  };
  const handleCloseEditModalDetails = () => setShowEditModalDetails(false);

  const [showEditModalTotals, setShowEditModalTotals] = useState(false);
  const handleShowEditModalTotals = (item) => {
    setRowTotals(item);
    setShowEditModalTotals(true);
  };
  const handleCloseEditModalTotals = () => setShowEditModalTotals(false);

  //delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleshowDeleteModal = (item) => {
    setRow(item);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const [showDeleteModalDetails, setShowDeleteModalDetails] = useState(false);
  const handleshowDeleteModalDetails = (item) => {
    setRowDetails(item);
    setShowDeleteModalDetails(true);
  };
  const handleCloseDeleteModalDetails = () => setShowDeleteModalDetails(false);

  const [showDeleteModalTotals, setShowDeleteModalTotals] = useState(false);
  const handleshowDeleteModalTotals = (item) => {
    setRowTotals(item);
    setShowDeleteModalTotals(true);
  };
  const handleCloseDeleteModalTotals = () => setShowDeleteModalTotals(false);

  //add modal
  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false);

  const [showAddModalDetails, setshowAddModalDetails] = useState(false);
  const handleCloseAddModalDetails = () => setshowAddModalDetails(false);

  const [showAddModalTotals, setshowAddModalTotals] = useState(false);
  const handleCloseAddModalTotals = () => setshowAddModalTotals(false);

  //redux
  //use selector hook
  const { sales, saleDetails, saleTotals } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      sales: SaleSelector.sales(state),
      saleDetails: SaleSelector.saleDetails(state),
      saleTotals: SaleSelector.saleTotals(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );

  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));

  useEffect(() => {
    dispatch(getSale(startDate));
    dispatch(getSaleDetails(startDate));
    dispatch(getSaleTotals(startDate));
  }, []);

  // ...and than map your thunks bellow
  const getSaleDetailsFromRedux = (startDate) => {
    dispatch(getSale(startDate));
    dispatch(getSaleDetails(startDate));
    dispatch(getSaleTotals(startDate));
    setStartDate(startDate);
  };

  const deleteSaleFromRedux = (saleId) => {
    dispatch(deleteSale(saleId));
    handleCloseDeleteModal();
  };

  const deleteSaleDetailsFromRedux = (saleDetailsId) => {
    dispatch(deleteSaleDetails(saleDetailsId));
    handleCloseDeleteModalDetails();
  };

  const deleteSaleTotalsFromRedux = (saleTotalId) => {
    dispatch(deleteSaleTotals(saleTotalId));
    handleCloseDeleteModalTotals();
  };

  const addSalesFromRedux = (sales) => {
    dispatch(addSale(sales, startDate));
    handleCloseAddModal();
  };

  const addSalesDetailsFromRedux = (salesDetails) => {
    dispatch(addSaleDetails(salesDetails, startDate));
    handleCloseAddModalDetails();
  };

  const addSalesTotalsFromRedux = (saleTotals) => {
    dispatch(addSaleTotals(saleTotals, startDate));
    handleCloseAddModalTotals();
  };

  const editSalesFromRedux = (sales) => {
    dispatch(editSale(sales, startDate));
    handleCloseEditModal();
  };

  const editSalesDetailsFromRedux = (salesDetails) => {
    dispatch(editSaleDetails(salesDetails, startDate));
    handleCloseEditModalDetails();
  };

  const editSaleTotalsFromRedux = (saleTotals) => {
    dispatch(editSaleTotals(saleTotals, startDate));
    handleCloseEditModalTotals();
  };

  return (
    <>
      <div className="sales">
        <div className="sales_start_page">
          <div className="bttns_inline">
            <Button
              variant="success"
              size="lg"
              onClick={() => setshowAddModal(true)}
            >
              {t("salespagemain.addbttn")}
            </Button>
            <Button
              variant="success"
              size="lg"
              onClick={() => setshowAddModalDetails(true)}
            >
              {t("salespagedetails.addbttn")}
            </Button>
            <Button
              variant="success"
              size="lg"
              onClick={() => setshowAddModalTotals(true)}
            >
              {t("salespagetotals.addbttn")}
            </Button>
          </div>
          <DatePickerWithRange
            onChangeDate={(startDate, endDate) =>
              getSaleDetailsFromRedux(startDate)
            }
            disabled={true}
          />
        </div>
        <h4>{t("salespagemain.titlesaletotals")}</h4>
        <TableBS
          headers={headersTotals}
          tableData={saleTotals && saleTotals}
          handleShowEditModal={(item) => handleShowEditModalTotals(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModalTotals(item)}
          sortedData={(sortedData) => handleTableDataTotals(sortedData)}
          hideSearchBar = {true}
        />
        <h4>{t("salespagemain.titledaybalance")}</h4>
        <TableBS
          headers={headersMain}
          tableData={sales && sales}
          handleShowEditModal={(item) => handleShowEditModal(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModal(item)}
          sortedData={(sortedData) => handleTableData(sortedData)}
          hideSearchBar = {true}
        />
        <h4>{t("salespagemain.titlesaleitems")}</h4>
        <TableBS
          headers={headersDetails}
          tableData={saleDetails && saleDetails}
          handleShowEditModal={(item) => handleShowEditModalDetails(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModalDetails(item)}
          sortedData={(sortedData) => handleTableDataDetails(sortedData)}
        />
        <DeleteSale
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={() => deleteSaleFromRedux(row.saleid)}
        />
        <DeleteSaleDetails
          show={showDeleteModalDetails}
          handleClose={handleCloseDeleteModalDetails}
          handleDelete={() =>
            deleteSaleDetailsFromRedux(rowDetails.saledetailsid)
          }
        />
        <DeleteSaleTotals
          show={showDeleteModalTotals}
          handleClose={handleCloseDeleteModalTotals}
          handleDelete={() => deleteSaleTotalsFromRedux(rowTotals.saleTotalId)}
        />
        <AddSales
          show={showAddModal}
          handleClose={handleCloseAddModal}
          handleAdd={(sales) => addSalesFromRedux(sales)}
        />
        <AddSalesDetail
          show={showAddModalDetails}
          handleClose={handleCloseAddModalDetails}
          handleAdd={(salesDetails) => addSalesDetailsFromRedux(salesDetails)}
        />
        <AddSalesTotal
          show={showAddModalTotals}
          handleClose={handleCloseAddModalTotals}
          handleAdd={(saleTotals) => addSalesTotalsFromRedux(saleTotals)}
        />
        <EditSales
          show={showEditModal}
          row={row}
          handleClose={handleCloseEditModal}
          handleEdit={(sales) => editSalesFromRedux(sales)}
        />
        <EditSalesDetails
          show={showEditModalDetails}
          row={rowDetails}
          handleClose={handleCloseEditModalDetails}
          handleEdit={(salesDetails) => editSalesDetailsFromRedux(salesDetails)}
        />
        <EditSalesTotals
          show={showEditModalTotals}
          row={rowTotals}
          handleClose={handleCloseEditModalTotals}
          handleEdit={(saleTotals) => editSaleTotalsFromRedux(saleTotals)}
        />
      </div>
    </>
  );
};

export default Sales;
