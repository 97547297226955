import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { ButtonGroup, FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//sort
const caretUpClassName = "fas fa-caret-up";
const caretDownClassName = "fas fa-caret-down";

const TableBS = (props) => {
  const { t } = useTranslation();

  const handleTableData = (sortedData) => {
    props.sortedData(sortedData);
  };

  const editItem = (item) => {
    props.handleShowEditModal(item);
  };

  const deleteItem = (item) => {
    props.handleshowDeleteModal(item);
  };

  //search field
  const handleSearch = () => {
    var table = document.getElementById("tableBS");
    var input = document.getElementById("searchField");
    let filter = input.value.toUpperCase();
    var rows = table.getElementsByTagName("TR");
    let flag = false;

    for (let row of rows) {
      let cellsFull = row.getElementsByTagName("TD");
      //remove last td, because we dont want to include td with edit/delete button
      let cells = [...cellsFull];
      cells = cells.slice(0, -1);
      for (let cell of cells) {
        if (cell.textContent.toUpperCase().indexOf(filter) > -1) {
          if (filter) {
            cell.style.backgroundColor = "#d4debd";
          } else {
            cell.style.backgroundColor = "";
          }

          flag = true;
        } else {
          cell.style.backgroundColor = "";
        }
      }

      if (flag) {
        row.style.display = "";
      } else {
        row.style.display = "none";
      }

      flag = false;
    }
  };

  //sort table
  const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  //   function clearArrow() {
  //     let carets = document.getElementsByClassName("caret");
  //     for (let caret of carets) {
  //       caret.className = "caret";
  //     }
  //   }

  function toggleArrow(event) {
    let element = event.target;
    let caret, field, reverse;
    if (element.tagName === "SPAN") {
      caret = element.getElementsByClassName("caret")[0];
      field = element.id;
    } else {
      caret = element;
      field = element.parentElement.id;
    }

    let iconClassName = caret.className;
    //clearArrow();
    if (iconClassName.includes(caretUpClassName)) {
      caret.className = `caret ${caretDownClassName}`;
      reverse = false;
    } else {
      reverse = true;
      caret.className = `caret ${caretUpClassName}`;
    }

    handleTableData(
      props.tableData && props.tableData.sort(sort_by(field, reverse))
    );
  }

  return (
    <>
    { (props.hideSearchBar == undefined || !props.hideSearchBar) &&
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          {t("table.search")}:
        </InputGroup.Text>
        <FormControl
          placeholder={t("table.search") + "..."}
          title="Type in something"
          aria-label="Search"
          aria-describedby="basic-addon1"
          id="searchField"
          onInput={handleSearch}
        />
      </InputGroup>
    }
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {props.headers &&
              props.headers.map((item, index) => {
                return (
                  <th key={index}>
                    <span id={item.id} onClick={(e) => toggleArrow(e)}>
                      {item.title}
                      <i className={`caret ${caretDownClassName}`} />
                    </span>
                  </th>
                );
              })}
            <th>
              <span>{t("table.actions")}</span>
            </th>
          </tr>
        </thead>
        <tbody id="tableBS">
          {props.tableData &&
            props.tableData.map((item, index) => {
              return (
                <tr key={index}>
                  {Object.keys(item).map(function (key, index) {
                    return <td key={index}>{item[key]}</td>;
                  })}
                  <td>
                    <ButtonGroup
                      aria-label="Basic example"
                      className="table-bttns"
                    >
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => editItem(item)}
                      >
                        {t("table.editbttn")}
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => deleteItem(item)}
                      >
                        {t("table.deletebttn")}
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default TableBS;
