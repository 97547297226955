import axios from "axios";
import { format } from "date-fns";

const axiosConfig = {
  headers: {
    Accept: "application/json",
  },
};

const baseAddress = "http://89.40.2.217:3333";
//const baseAddress = "http://localhost:3333";

//Non thunk actions
export function loginMain(loginData) {
  return {
    type: "LOGIN",
    loginData: loginData,
  };
}

export function logoutMain(isValid) {
  return {
    type: "LOGOUT",
    isValid: isValid,
  };
}

//cutting page
export function getCuttingsMain(cuttings) {
  return {
    type: "SELECT",
    cuttings: cuttings,
  };
}

export function sortTableDataMain(sortedData) {
  return {
    type: "SORTED",
    sortedData: sortedData,
  };
}

export function deleteCuttingMain(cuttingId) {
  return {
    type: "DELETE",
    cuttingId: cuttingId,
  };
}

export function getGroupMain(groups) {
  return {
    type: "GROUPS",
    groups: groups,
  };
}

//species page
export function getSpeciesMain(species) {
  return {
    type: "GET_SPECIES",
    species: species,
  };
}

export function sortSpeciesMain(sortedData) {
  return {
    type: "SORTED_SPECIES",
    sortedData: sortedData,
  };
}

export function deleteSpeciesMain(speciesId) {
  return {
    type: "DELETE_SPECIES",
    speciesId: speciesId,
  };
}

//status page
export function getStockMain(stocks) {
  return {
    type: "GET_STOCKS",
    stocks: stocks,
  };
}

export function sortStockMain(sortedData) {
  return {
    type: "SORTED_STOCKS",
    sortedData: sortedData,
  };
}

export function deleteStockMain(stockId) {
  return {
    type: "DELETE_STOCKS",
    stockId: stockId,
  };
}

//comment page
export function getCommentMain(comments) {
  return {
    type: "GET_COMMENT",
    comments: comments,
  };
}

export function deleteCommentMain(commentId) {
  return {
    type: "DELETE_COMMENT",
    commentId: commentId,
  };
}

//store page
export function getStoresMain(stores) {
  return {
    type: "GET_STORES",
    stores: stores,
  };
}

export function sortStoresMain(sortedData) {
  return {
    type: "SORTED_STORES",
    sortedData: sortedData,
  };
}

export function deleteStoreMain(storeId) {
  return {
    type: "DELETE_STORE",
    storeId: storeId,
  };
}

//user page
export function getUsersMain(users) {
  return {
    type: "GET_USERS",
    users: users,
  };
}

export function sortUsersMain(sortedData) {
  return {
    type: "SORTED_USERS",
    sortedData: sortedData,
  };
}

export function deleteUserMain(userId) {
  return {
    type: "DELETE_USER",
    userId: userId,
  };
}

export function getUserRolesMain(roles) {
  return {
    type: "GET_USER_ROLES",
    roles: roles,
  };
}

export function changePasswordMain(message) {
  return {
    type: "CHANGE_PASSWORD",
    message: message,
  };
}

//sales page
export function getSaleMain(sales) {
  return {
    type: "GET_SALE",
    sales: sales,
  };
}

export function sortSaleMain(sortedData) {
  return {
    type: "SORTED_SALE",
    sortedData: sortedData,
  };
}

export function deleteSaleMain(saleId) {
  return {
    type: "DELETE_SALE",
    saleId: saleId,
  };
}

export function getSaleDetailsMain(saleDetails) {
  return {
    type: "GET_SALE_DETAILS",
    saleDetails: saleDetails,
  };
}

export function sortSaleDetailsMain(sortedData) {
  return {
    type: "SORTED_SALE_DETAILS",
    sortedData: sortedData,
  };
}

export function deleteSaleDetailsMain(saleDetailsId) {
  return {
    type: "DELETE_SALE_DETAILS",
    saleDetailsId: saleDetailsId,
  };
}

export function getSaleTotalsMain(saleTotals) {
  return {
    type: "GET_SALE_TOTALS",
    saleTotals: saleTotals,
  };
}

export function sortSaleTotalsMain(sortedData) {
  return {
    type: "SORTED_SALE_TOTALS",
    sortedData: sortedData,
  };
}

export function deleteSaleTotalsMain(saleTotalId) {
  return {
    type: "DELETE_SALE_TOTALS",
    saleTotalId: saleTotalId,
  };
}

//services page
export function getServicesMain(services) {
  return {
    type: "GET_SERVICES",
    services: services,
  };
}

export function sortServicesMain(sortedData) {
  return {
    type: "SORTED_SERVICES",
    sortedData: sortedData,
  };
}

export function deleteServicesMain(serviceId) {
  return {
    type: "DELETE_SERVICES",
    serviceId: serviceId,
  };
}

export function getSpeciesAndGroupsMain(speciesandgroups) {
  return {
    type: "GET_SPECIES_GROUPS",
    speciesandgroups: speciesandgroups,
  };
}

export function getCutsMain(noofcuts) {
  return {
    type: "GET_CUTS",
    noofcuts: noofcuts,
  };
}

//orders page
export function getOrdersMain(orders) {
  return {
    type: "GET_ORDERS",
    orders: orders,
  };
}

export function sortOrdersMain(sortedData) {
  return {
    type: "SORTED_ORDERS",
    sortedData: sortedData,
  };
}

export function deleteOrdersMain(orderId) {
  return {
    type: "DELETE_ORDERS",
    orderId: orderId,
  };
}

//calendar page
export function getCalendarMain(events) {
  return {
    type: "GET_EVENTS",
    events: events,
  };
}

export function deleteCalendarMain(id) {
  return {
    type: "DELETE_EVENTS",
    id: id,
  };
}

//report page
export function getdailyCuttingReportMain(cutting) {
  return {
    type: "GET_DAILY_CUTTING",
    cutting: cutting,
  };
}
export function getdailyStateReportMain(cuttingStatus) {
  return {
    type: "GET_DAILY_STATUS",
    cuttingStatus: cuttingStatus,
  };
}
export function getdailyFinancialReportMain(financial) {
  return {
    type: "GET_DAILY_FINANCIAL",
    financial: financial,
  };
}
export function getweeklyCuttingReportMain(cutting) {
  return {
    type: "GET_WEEKLY_CUTTING",
    cutting: cutting,
  };
}
export function getmonthlyCuttingReportMain(cutting) {
  return {
    type: "GET_MONTHLY_CUTTING",
    cutting: cutting,
  };
}
export function getmonthlyCuttingTotalsReportMain(cutting) {
  return {
    type: "GET_MONTHLY_CUTTING_TOTALS",
    cutting: cutting,
  };
}
export function getmonthlyFinancialReportMain(financial) {
  return {
    type: "GET_MONTHLY_FINANCIAL",
    financial: financial,
  };
}

export function getmonthlyExpensesReportMain(expenses) {
  return {
    type: "GET_MONTHLY_EXPENSES",
    expenses: expenses,
  };
}

//Thunk actions
export const login = (username, password) => async (dispatch) => {
  axios
    .post(baseAddress + "/login", {
      username: username,
      password: password,
    })
    .then((res) => {
      dispatch(loginMain(res.data));
    });
};

export const logout = (isValid) => async (dispatch) => {
  dispatch(logoutMain(isValid));
};

export const getCuttings = (startDate, endDate) => async (dispatch) => {
  axios
    .get(
      baseAddress + "/cutting?startDate=" + startDate + "&endDate=" + endDate
    )
    .then((res) => {
      dispatch(getCuttingsMain(res.data));
    });
};

export const sortTableData = (sortedData) => async (dispatch) => {
  dispatch(sortTableDataMain(sortedData));
};

export const deleteCutting = (cuttingId) => async (dispatch) => {
  axios.delete(baseAddress + "/cutting?cuttingId=" + cuttingId).then((res) => {
    dispatch(deleteCuttingMain(cuttingId));
  });
};

export const addCutting = (cutting, startDate, endDate) => async (dispatch) => {
  axios
    .post(baseAddress + "/cutting", {
      speciesId: cutting.speciesId,
      groupNumber: cutting.groupNumber,
      date: cutting.date,
      noOfCuts: cutting.noOfCuts,
      noOfParents: cutting.noOfParents,
      platform: cutting.platform,
      storeId: cutting.storeId,
      plantingDate: cutting.plantingDate,
      comment: cutting.comment,
      treatment: cutting.treatment,
    })
    .then((res) => {
      dispatch(getCuttings(startDate, endDate));
    });
};

export const editCutting =
  (cutting, startDate, endDate) => async (dispatch) => {
    axios
      .put(baseAddress + "/cutting", {
        cuttingId: cutting.cuttingid,
        speciesId: cutting.speciesId,
        groupNumber: cutting.groupNumber,
        date: cutting.date,
        noOfCuts: cutting.noOfCuts,
        noOfParents: cutting.noOfParents,
        platform: cutting.platform,
        storeId: cutting.storeId,
        plantingDate: cutting.plantingDate,
        comment: cutting.comment,
        treatment: cutting.treatment,
      })
      .then((res) => {
        dispatch(getCuttings(startDate, endDate));
      });
  };

export const getGroup = () => async (dispatch) => {
  axios.get(baseAddress + "/group").then((res) => {
    dispatch(getGroupMain(res.data));
  });
};

export const getSpecies = () => async (dispatch) => {
  axios.get(baseAddress + "/species").then((res) => {
    dispatch(getSpeciesMain(res.data));
  });
};

export const sortSpecies = (sortedData) => async (dispatch) => {
  dispatch(sortSpeciesMain(sortedData));
};

export const addSpecies = (species) => async (dispatch) => {
  axios
    .post(baseAddress + "/species", {
      speciesName: species.speciesName,
    })
    .then((res) => {
      dispatch(getSpecies());
    });
};

export const editSpecies = (species) => async (dispatch) => {
  axios
    .put(baseAddress + "/species", {
      speciesId: species.speciesId,
      speciesName: species.speciesName,
    })
    .then((res) => {
      dispatch(getSpecies());
    });
};

export const deleteSpecies = (speciesId) => async (dispatch) => {
  axios.delete(baseAddress + "/species?speciesId=" + speciesId).then((res) => {
    dispatch(deleteSpeciesMain(speciesId));
  });
};

export const getStock = (startDate, endDate) => async (dispatch) => {
  axios
    .get(baseAddress + "/stock?startDate=" + startDate + "&endDate=" + endDate)
    .then((res) => {
      dispatch(getStockMain(res.data));
    });
};

export const sortStock = (sortedData) => async (dispatch) => {
  dispatch(sortStockMain(sortedData));
};

export const addStock = (stock, startDate, endDate) => async (dispatch) => {
  axios
    .post(baseAddress + "/stock", {
      date: stock.date,
      speciesId: stock.speciesId,
      morningStockRecovery: stock.morningStockRecovery,
      morningStockSale: stock.morningStockSale,
      sold: stock.sold,
      thrownAwayRecovery: stock.thrownAwayRecovery,
      thrownAwaySale: stock.thrownAwaySale,
      serviceToRecovery: stock.serviceToRecovery,
      recoveryToSale: stock.recoveryToSale,
      totalRecovery: stock.totalRecovery,
      totalSale: stock.totalSale,
    })
    .then((res) => {
      dispatch(getStock(startDate, endDate));
    });
};

export const editStock = (stock, startDate, endDate) => async (dispatch) => {
  axios
    .put(baseAddress + "/stock", {
      stockId: stock.stockid,
      date: stock.date,
      speciesId: stock.speciesId,
      morningStockRecovery: stock.morningStockRecovery,
      morningStockSale: stock.morningStockSale,
      sold: stock.sold,
      thrownAwayRecovery: stock.thrownAwayRecovery,
      thrownAwaySale: stock.thrownAwaySale,
      serviceToRecovery: stock.serviceToRecovery,
      recoveryToSale: stock.recoveryToSale,
      totalRecovery: stock.totalRecovery,
      totalSale: stock.totalSale,
    })
    .then((res) => {
      dispatch(getStock(startDate, endDate));
    });
};

export const deleteStock = (stockId) => async (dispatch) => {
  axios.delete(baseAddress + "/stock?stockId=" + stockId).then((res) => {
    dispatch(deleteStockMain(stockId));
  });
};

export const getComment = (startDate) => async (dispatch) => {
  axios
    .get(baseAddress + "/cuttingComment?startDate=" + startDate)
    .then((res) => {
      dispatch(getCommentMain(res.data));
    });
};

export const addComment = (comment) => async (dispatch) => {
  axios
    .post(baseAddress + "/cuttingComment", {
      date: comment.date,
      text: comment.text,
      userId: 0,
    })
    .then((res) => {
      dispatch(getComment(comment.date));
    });
};

export const deleteComment = (commentId) => async (dispatch) => {
  axios
    .delete(baseAddress + "/cuttingComment?commentId=" + commentId)
    .then((res) => {
      dispatch(deleteCommentMain(commentId));
    });
};

export const getStores = () => async (dispatch) => {
  axios.get(baseAddress + "/stores").then((res) => {
    dispatch(getStoresMain(res.data));
  });
};

export const sortStores = (sortedData) => async (dispatch) => {
  dispatch(sortStoresMain(sortedData));
};

export const addStore = (store) => async (dispatch) => {
  axios
    .post(baseAddress + "/stores", {
      storeName: store.storeName,
    })
    .then((res) => {
      dispatch(getStores());
    });
};

export const editStore = (store) => async (dispatch) => {
  axios
    .put(baseAddress + "/stores", {
      storeId: store.storeId,
      storeName: store.storeName,
    })
    .then((res) => {
      dispatch(getStores());
    });
};

export const deleteStore = (storeId) => async (dispatch) => {
  axios.delete(baseAddress + "/stores?storeId=" + storeId).then((res) => {
    dispatch(deleteStoreMain(storeId));
  });
};

export const getUsers = () => async (dispatch) => {
  axios.get(baseAddress + "/users").then((res) => {
    dispatch(getUsersMain(res.data));
  });
};

export const sortUsers = (sortedData) => async (dispatch) => {
  dispatch(sortUsersMain(sortedData));
};

export const addUser = (user) => async (dispatch) => {
  axios
    .post(baseAddress + "/users", {
      firstName: user.userFName,
      lastName: user.userLName,
      email: user.userEmail,
      password: user.userPass,
      roleId: user.userRole,
      username: user.userName,
    })
    .then((res) => {
      dispatch(getUsers());
    });
};

export const editUser = (user) => async (dispatch) => {
  axios
    .put(baseAddress + "/users", {
      userId: user.userId,
      firstName: user.userFName,
      lastName: user.userLName,
      email: user.userEmail,
      roleId: user.userRole,
    })
    .then((res) => {
      dispatch(getUsers());
    });
};

export const deleteUser = (userId) => async (dispatch) => {
  axios.delete(baseAddress + "/users?userId=" + userId).then((res) => {
    dispatch(deleteUserMain(userId));
  });
};

export const getUserRoles = () => async (dispatch) => {
  axios.get(baseAddress + "/roles").then((res) => {
    dispatch(getUserRolesMain(res.data));
  });
};

export const changePassword =
  (userId, oldPass, newPass) => async (dispatch) => {
    axios
      .put(baseAddress + "/password", {
        userId: userId,
        oldPass: oldPass,
        newPass: newPass,
      })
      .then((res) => {
        var message = "Password has been changed successfully";
        dispatch(changePasswordMain(message));
      });
  };

export const getSale = (startDate) => async (dispatch) => {
  axios.get(baseAddress + "/sale?date=" + startDate).then((res) => {
    dispatch(getSaleMain(res.data));
  });
};

export const sortSale = (sortedData) => async (dispatch) => {
  dispatch(sortSaleMain(sortedData));
};

export const addSale = (sales, startDate) => async (dispatch) => {
  axios
    .post(baseAddress + "/sale", {
      date: sales.date,
      paymentToAccount: sales.paymentToAccount,
      cashBalance: sales.cashBalance,
      accountBalance: sales.accountBalance,
      comment: sales.comment,
    })
    .then((res) => {
      dispatch(getSale(startDate));
    });
};

export const editSale = (sales, startDate) => async (dispatch) => {
  axios
    .put(baseAddress + "/sale", {
      saleId: sales.saleId,
      date: sales.date,
      paymentToAccount: sales.paymentToAccount,
      cashBalance: sales.cashBalance,
      accountBalance: sales.accountBalance,
      comment: sales.comment,
    })
    .then((res) => {
      dispatch(getSale(startDate));
    });
};

export const deleteSale = (saleId) => async (dispatch) => {
  axios.delete(baseAddress + "/sale?saleId=" + saleId).then((res) => {
    dispatch(deleteSaleMain(saleId));
  });
};

export const getSaleDetails = (startDate) => async (dispatch) => {
  axios.get(baseAddress + "/saleDetails?date=" + startDate).then((res) => {
    dispatch(getSaleDetailsMain(res.data));
  });
};

export const sortSaleDetails = (sortedData) => async (dispatch) => {
  dispatch(sortSaleDetailsMain(sortedData));
};

export const addSaleDetails = (sales, startDate) => async (dispatch) => {
  axios
    .post(baseAddress + "/saleDetails", {
      date: sales.date,
      code: sales.code,
      soldCount: sales.soldCount,
      amount: sales.amount,
      cashPaymentDescription: sales.cashPaymentDescription,
      cashPaymentAmount: sales.cashPaymentAmount,
      accountPaymentDescription: sales.accountPaymentDescription,
      accountPaymentAmount: sales.accountPaymentAmount,
    })
    .then((res) => {
      dispatch(getSaleDetails(startDate));
    });
};

export const editSaleDetails = (sales, startDate) => async (dispatch) => {
  axios
    .put(baseAddress + "/saleDetails", {
      saleDetailsId: sales.saleDetailsId,
      date: sales.date,
      code: sales.code,
      soldCount: sales.soldCount,
      amount: sales.amount,
      cashPaymentDescription: sales.cashPaymentDescription,
      cashPaymentAmount: sales.cashPaymentAmount,
      accountPaymentDescription: sales.accountPaymentDescription,
      accountPaymentAmount: sales.accountPaymentAmount,
    })
    .then((res) => {
      dispatch(getSaleDetails(startDate));
    });
};

export const deleteSaleDetails = (saleDetailsId) => async (dispatch) => {
  axios
    .delete(baseAddress + "/saleDetails?saleDetailsId=" + saleDetailsId)
    .then((res) => {
      dispatch(deleteSaleDetailsMain(saleDetailsId));
    });
};

export const getSaleTotals = (startDate) => async (dispatch) => {
  axios.get(baseAddress + "/saleTotal?date=" + startDate).then((res) => {
    dispatch(getSaleTotalsMain(res.data));
  });
};

export const addSaleTotals = (sales, startDate) => async (dispatch) => {
  axios
    .post(baseAddress + "/saleTotal", {
      date: sales.date,
      totalSoldCount: sales.totalSoldCount,
      totalAmount: sales.totalAmount,
      totalCashPaymentAmount: sales.totalCashPaymentAmount,
      totalAccountPaymentAmount: sales.totalAccountPaymentAmount,
    })
    .then((res) => {
      dispatch(getSaleTotals(startDate));
    });
};

export const sortSaleTotals = (sortedData) => async (dispatch) => {
  dispatch(sortSaleTotalsMain(sortedData));
};

export const editSaleTotals = (sales, startDate) => async (dispatch) => {
  axios
    .put(baseAddress + "/saleTotal", {
      saleTotalId: sales.saleTotalId,
      date: sales.date,
      totalSoldCount: sales.totalSoldCount,
      totalAmount: sales.totalAmount,
      totalCashPaymentAmount: sales.totalCashPaymentAmount,
      totalAccountPaymentAmount: sales.totalAccountPaymentAmount,
    })
    .then((res) => {
      dispatch(getSaleTotals(startDate));
    });
};

export const deleteSaleTotals = (saleTotalId) => async (dispatch) => {
  axios
    .delete(baseAddress + "/saleTotal?saleTotalId=" + saleTotalId)
    .then((res) => {
      dispatch(deleteSaleDetailsMain(saleTotalId));
    });
};

export const getServices = (startDate, endDate) => async (dispatch) => {
  axios
    .get(
      baseAddress + "/services?startDate=" + startDate + "&endDate=" + endDate
    )
    .then((res) => {
      dispatch(getServicesMain(res.data));
    });
};

export const sortServices = (sortedData) => async (dispatch) => {
  dispatch(sortServicesMain(sortedData));
};

export const addServices =
  (services, startDate, endDate) => async (dispatch) => {
    axios
      .post(baseAddress + "/services", {
        date: services.date,
        cuttingId: services.cuttingId,
        noOfDay: services.noOfDay,
        viewing: services.viewing,
        removedNo: services.removedNo,
        returnedNo: services.returnedNo,
        thrownOutNo: services.thrownOutNo,
      })
      .then((res) => {
        dispatch(getServices(startDate, endDate));
      });
  };

export const editServices =
  (services, startDate, endDate) => async (dispatch) => {
    axios
      .put(baseAddress + "/services", {
        serviceId: services.serviceId,
        date: services.date,
        noOfDay: services.noOfDay,
        viewing: services.viewing,
        removedNo: services.removedNo,
        returnedNo: services.returnedNo,
        thrownOutNo: services.thrownOutNo,
      })
      .then((res) => {
        dispatch(getServices(startDate, endDate));
      });
  };

export const deleteServices = (serviceId) => async (dispatch) => {
  axios.delete(baseAddress + "/services?serviceId=" + serviceId).then((res) => {
    dispatch(deleteServicesMain(serviceId));
  });
};

export const getSpeciesAndGroups = (startDate) => async (dispatch) => {
  axios.get(baseAddress + "/cuttingForDate?date=" + startDate).then((res) => {
    dispatch(getSpeciesAndGroupsMain(res.data));
  });
};

export const getCuts = (cuttingid) => async (dispatch) => {
  if (cuttingid === 0) dispatch(getCutsMain(0));
  else {
    axios.get(baseAddress + "/noofcuts?cuttingId=" + cuttingid).then((res) => {
      dispatch(getCutsMain(res.data));
    });
  }
};

export const getOrders = (startDate) => async (dispatch) => {
  axios.get(baseAddress + "/order?date=" + startDate).then((res) => {
    dispatch(getOrdersMain(res.data));
  });
};

export const sortOrders = (sortedData) => async (dispatch) => {
  dispatch(sortOrdersMain(sortedData));
};

export const addOrders = (order, startDate) => async (dispatch) => {
  axios
    .post(baseAddress + "/order", {
      date: order.date,
      pickupDate: order.pickupDate,
      customerCode: order.customerCode,
      speciesId: order.speciesId,
      quantity: order.quantity,
      comment: order.comment,
      total: order.total,
    })
    .then((res) => {
      dispatch(getOrders(startDate));
    });
};

export const editOrders = (order, startDate) => async (dispatch) => {
  axios
    .put(baseAddress + "/order", {
      orderId: order.orderid,
      date: order.date,
      pickupDate: order.pickupDate,
      customerCode: order.customerCode,
      speciesId: order.speciesId,
      quantity: order.quantity,
      comment: order.comment,
      total: order.total,
    })
    .then((res) => {
      dispatch(getOrders(startDate));
    });
};

export const deleteOrders = (orderId) => async (dispatch) => {
  axios.delete(baseAddress + "/order?orderId=" + orderId).then((res) => {
    dispatch(deleteOrdersMain(orderId));
  });
};

export const getCalendar = () => async (dispatch) => {
  axios.get(baseAddress + "/calendarorder").then((res) => {
    dispatch(getCalendarMain(res.data));
  });
};

export const addCalendar = (calendar) => async (dispatch) => {
  axios
    .post(baseAddress + "/calendarorder", {
      title: calendar.title,
      start: calendar.start,
    })
    .then((res) => {
      dispatch(getCalendar());
    });
};

export const deleteCalendar = (id) => async (dispatch) => {
  axios.delete(baseAddress + "/calendarorder?id=" + id).then((res) => {
    dispatch(deleteCalendarMain(id));
  });
};

export const getdailyCuttingReport = (startDate) => async (dispatch) => {
  axios
    .get(baseAddress + "/dailyCuttingReport?date=" + startDate)
    .then((res) => {
      dispatch(getdailyCuttingReportMain(res.data));
    });
};

export const getdailyStateReport = (startDate) => async (dispatch) => {
  axios.get(baseAddress + "/dailyStateReport?date=" + startDate).then((res) => {
    dispatch(getdailyStateReportMain(res.data));
  });
};

export const getdailyFinancialReport = (startDate) => async (dispatch) => {
  axios
    .get(baseAddress + "/dailyFinancialReport?date=" + startDate)
    .then((res) => {
      dispatch(getdailyFinancialReportMain(res.data));
    });
};

export const getweeklyCuttingReport = (startDate) => async (dispatch) => {
  axios
    .get(baseAddress + "/weeklyCuttingReport?date=" + startDate)
    .then((res) => {
      dispatch(getweeklyCuttingReportMain(res.data));
    });
};

export const getmonthlyCuttingReport =
  (startDate) => async (dispatch) => {
    axios
      .get(
        baseAddress +
          "/monthlyCuttingReport?date=" +
          startDate
      )
      .then((res) => {
        dispatch(getmonthlyCuttingReportMain(res.data));
      });
  };

export const getmonthlyCuttingTotalsReport =
  (startDate) => async (dispatch) => {
    axios
      .get(
        baseAddress +
          "/monthlyCuttingTotalsReport?date=" +
          startDate
      )
      .then((res) => {
        dispatch(getmonthlyCuttingTotalsReportMain(res.data));
      });
  };

export const getmonthlyFinancialReport = (startDate) => async (dispatch) => {
  axios
    .get(baseAddress + "/monthlyFinancialReport?date=" + startDate)
    .then((res) => {
      dispatch(getmonthlyFinancialReportMain(res.data));
    });
};

export const getmonthlyExpensesReport = (startDate) => async (dispatch) => {
  axios
    .get(baseAddress + "/monthlyExpensesReport?date=" + startDate)
    .then((res) => {
      dispatch(getmonthlyExpensesReportMain(res.data));
    });
};
