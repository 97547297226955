import { combineReducers } from "redux";
import calendarReducer from "./calendarReducer";
import commentReducer from "./commentReducer";
import cuttingReducer from "./cuttingReducer";
import loginReducer from "./loginReducer";
import ordersReducer from "./ordersReducer";
import reportReducer from "./reportReducer";
import salesReducer from "./salesReducer";
import serviceReducer from "./serviceReducer";
import speciesReducer from "./speciesReducer";
import statusReducer from "./statusReducer";
import storeReducer from "./storeReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  cutting: cuttingReducer,
  species: speciesReducer,
  stock: statusReducer,
  comment: commentReducer,
  store: storeReducer,
  user: userReducer,
  sales: salesReducer,
  services: serviceReducer,
  orders: ordersReducer,
  calendar: calendarReducer,
  report: reportReducer,
});

export default rootReducer;
