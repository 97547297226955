import React from "react";

const Home = (props) => {
  return (
    <>
      <div className="home">
        <div className="logo"></div>
      </div>
    </>
  );
};

export default Home;
