import React, { useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/actions";
import LoginSelector from "../../store/selectors/loginSelectors";

const Login = (props) => {
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  //redux
  //use selector hook
  const { isValid } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      isValid: LoginSelector.isValidLogin(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );

  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(values.username, values.password));
    if (!isValid) setShowAlert(true);
    clearValues();
  };

  const clearValues = () => {
    setValues({ username: "", password: "" });
  };

  //alert
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      <div className="login">
        <form onSubmit={handleSubmit} className="login-container">
          <input
            className="login-input"
            type="text"
            name="username"
            placeholder="Username"
            value={values.username}
            onChange={handleChange}
          />

          <input
            className="login-input"
            type="password"
            name="password"
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
          />
          <button className="login-bttn" type="submit">
            Login
          </button>
        </form>
        {showAlert && (
          <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <p>Wrong username or password. Please, try again!</p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default Login;
