// Use selectors to extract data
const users = (state) => state.user.users;
const roles = (state) => state.user.roles;
const changedPassMessage = (state) => state.user.changedPassMessage;

// You could also create selectors with some logic inside
// const getWhereIAmFrom = (state) => state.cities.allCities.find(c => c.cityName === 'Belgrade');

export default {
  users,
  roles,
  changedPassMessage,
};
