import React, { useEffect, useState } from "react";
import TableBS from "../Table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  sortSpecies,
  getSpecies,
  deleteSpecies,
  addSpecies,
  editSpecies,
} from "../../store/actions/actions";
import SpeciesSelector from "../../store/selectors/speciesSelector";
import EditSpecies from "./EditModal";
import DeleteSpecies from "./DeleteModal";
import AddSpecies from "./AddModal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export const Species = (props) => {
  const { t } = useTranslation();

  const headers = [
    { title: t("speciespage.title1"), id: "speciesid" },
    { title: t("speciespage.title2"), id: "speciesname" },
  ];

  //selected row
  const [row, setRow] = useState({});

  //edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (item) => {
    setRow(item);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);

  //delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleshowDeleteModal = (item) => {
    setRow(item);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  //add modal
  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false);

  //tableData
  const handleTableData = (sortedData) => {
    dispatch(sortSpecies(sortedData));
  };

  //redux
  //use selector hook
  const { species } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      species: SpeciesSelector.species(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSpecies());
  }, []);

  const deleteSpeciesFromRedux = (speciesId) => {
    dispatch(deleteSpecies(speciesId));
    handleCloseDeleteModal();
  };

  const addSpeciesFromRedux = (species) => {
    dispatch(addSpecies(species));
    handleCloseAddModal();
  };

  const editSpeciesFromRedux = (species) => {
    dispatch(editSpecies(species));
    handleCloseEditModal();
  };

  return (
    <>
      <div className="species">
        <div className="bttns_inline" onClick={() => setshowAddModal(true)}>
          <Button variant="success" size="lg">
            {t("speciespage.addbttn")}
          </Button>
        </div>
        <TableBS
          headers={headers}
          tableData={species && species}
          handleShowEditModal={(item) => handleShowEditModal(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModal(item)}
          sortedData={(sortedData) => handleTableData(sortedData)}
        />
        <EditSpecies
          show={showEditModal}
          row={row}
          handleClose={handleCloseEditModal}
          handleEdit={(species) => editSpeciesFromRedux(species)}
        />
        <DeleteSpecies
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={() => deleteSpeciesFromRedux(row.speciesid)}
        />
        <AddSpecies
          show={showAddModal}
          handleClose={handleCloseAddModal}
          handleAdd={(species) => addSpeciesFromRedux(species)}
        />
      </div>
    </>
  );
};

export default Species;
