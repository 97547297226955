const initialState = {
  species: [],
};

const speciesReducer = (state = initialState, action) => {
  if (action.type === "GET_SPECIES") {
    return {
      ...state,
      species: action.species,
    };
  } else if (action.type === "SORTED_SPECIES") {
    return {
      ...state,
      species: [...action.sortedData],
    };
  } else if (action.type === "DELETE_SPECIES") {
    return {
      ...state,
      species: state.species.filter(
        (item) => item.speciesid !== action.speciesId
      ),
    };
  } else {
    return state;
  }
};

export default speciesReducer;
