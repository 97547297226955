// Use selectors to extract data
const isValidLogin = (state) => state.login.isValid;
const userId = (state) => state.login.userId;
const pages = (state) => state.login.pages;
const roleName = (state) => state.login.roleName;
// You could also create selectors with some logic inside
// const getWhereIAmFrom = (state) => state.cities.allCities.find(c => c.cityName === 'Belgrade');

export default {
  isValidLogin,
  userId,
  pages,
  roleName,
};
