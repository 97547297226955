import React, { useEffect, useState } from "react";
import TableBS from "../Table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  sortStock,
  getStock,
  deleteStock,
  addStock,
  editStock,
  getSpecies,
} from "../../store/actions/actions";
import StockSelector from "../../store/selectors/stockSlector";
import SpeciesSelector from "../../store/selectors/speciesSelector";
import EditStock from "./EditModal";
import DeleteStock from "./DeleteModal";
import AddStock from "./AddModal";
import Button from "react-bootstrap/Button";
import DatePickerWithRange from "../DatePicker";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export const PlantStatus = (props) => {
  const { t } = useTranslation();

  const headers = [
    { title: t("statuspage.title1"), id: "stockid" },
    { title: t("statuspage.title3"), id: "speciesname" },
    { title: t("statuspage.title2"), id: "date" },
    { title: t("statuspage.title4"), id: "morningstockrecovery" },
    { title: t("statuspage.title5"), id: "morningstocksale" },
    { title: t("statuspage.title6"), id: "sold" },
    { title: t("statuspage.title7"), id: "thrownawayrecovery" },
    { title: t("statuspage.title8"), id: "thrownawaysale" },
    { title: t("statuspage.title9"), id: "servicetorecovery" },
    { title: t("statuspage.title10"), id: "recoverytosale" },
    { title: t("statuspage.title11"), id: "totalrecovery" },
    { title: t("statuspage.title12"), id: "totalsale" },
  ];

  //selected row
  const [row, setRow] = useState({});

  //edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (item) => {
    setRow(item);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);

  //delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleshowDeleteModal = (item) => {
    setRow(item);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  //add modal
  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false);

  //tableData
  const handleTableData = (sortedData) => {
    dispatch(sortStock(sortedData));
  };

  //redux
  //use selector hook
  const { stocks, species } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      stocks: StockSelector.stocks(state),
      species: SpeciesSelector.species(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

  useEffect(() => {
    dispatch(getStock(startDate, endDate));
    dispatch(getSpecies());
  }, []);

  // ...and than map your thunks bellow
  const getStockFromRedux = (startDate, endDate) => {
    dispatch(getStock(startDate, endDate));
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const deleteStockFromRedux = (stockId) => {
    dispatch(deleteStock(stockId));
    handleCloseDeleteModal();
  };

  const addStockFromRedux = (stocks) => {
    dispatch(addStock(stocks, startDate, endDate));
    handleCloseAddModal();
  };

  const editStockFromRedux = (stocks) => {
    dispatch(editStock(stocks, startDate, endDate));
    handleCloseEditModal();
  };

  return (
    <>
      <div className="status">
        <div className="status_start_page">
          <div className="bttns_inline" onClick={() => setshowAddModal(true)}>
            <Button variant="success" size="lg">
              {t("statuspage.addbttn")}
            </Button>
          </div>
          <DatePickerWithRange
            onChangeDate={(startDate, endDate) =>
              getStockFromRedux(startDate, endDate)
            }
          />
        </div>
        <TableBS
          headers={headers}
          tableData={stocks && stocks}
          handleShowEditModal={(item) => handleShowEditModal(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModal(item)}
          sortedData={(sortedData) => handleTableData(sortedData)}
        />
        <EditStock
          show={showEditModal}
          row={row}
          handleClose={handleCloseEditModal}
          handleEdit={(stocks) => editStockFromRedux(stocks)}
          species={species}
        />
        <DeleteStock
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={() => deleteStockFromRedux(row.stockid)}
        />

        <AddStock
          show={showAddModal}
          handleClose={handleCloseAddModal}
          handleAdd={(stocks) => addStockFromRedux(stocks)}
          species={species}
        />
      </div>
    </>
  );
};

export default PlantStatus;
