const services = (state) => state.services.services;
const speciesandgroups = (state) => state.services.speciesandgroups;
const noofcuts = (state) => state.services.noofcuts;

// You could also create selectors with some logic inside
// const getWhereIAmFrom = (state) => state.cities.allCities.find(c => c.cityName === 'Belgrade');

export default {
  services,
  speciesandgroups,
  noofcuts,
};
