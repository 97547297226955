import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import { useTranslation } from "react-i18next";

export const AddSalesDetail = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleAdd({
        date: format(date, "yyyy-MM-dd"),
        code: values.code,
        soldCount: parseFloat(values.soldCount),
        amount: parseFloat(values.amount),
        cashPaymentDescription: values.cashPaymentDescription,
        cashPaymentAmount: parseFloat(values.cashPaymentAmount),
        accountPaymentDescription: values.accountPaymentDescription,
        accountPaymentAmount: parseFloat(values.accountPaymentAmount),
      });
      event.preventDefault();
    }
    setValidated(false);
    clearValues();
  };

  const clearValues = () => {
    setDate(new Date());
    setValues({
      code: "",
      soldCount: 0,
      amount: 0,
      cashPaymentDescription: "",
      cashPaymentAmount: 0,
      accountPaymentDescription: "",
      accountPaymentAmount: 0,
    });
  };

  //date picker
  const [date, setDate] = useState(new Date());

  //other values
  const [values, setValues] = useState({
    code: "",
    soldCount: 0,
    amount: 0,
    cashPaymentDescription: "",
    cashPaymentAmount: 0,
    accountPaymentDescription: "",
    accountPaymentAmount: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("salespagedetails.addmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title2")}:</Form.Label>
              <DatePicker
                date={date}
                onDateChange={setDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title3")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.code")}
                name="code"
                value={values.code}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title4")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="soldCount"
                value={values.soldCount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title5")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="amount"
                value={values.amount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title10")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.cashpayment")}
                name="cashPaymentDescription"
                value={values.cashPaymentDescription}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title11")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="cashPaymentAmount"
                value={values.cashPaymentAmount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title12")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.accountpayment")}
                name="accountPaymentDescription"
                value={values.accountPaymentDescription}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagedetails.title13")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="accountPaymentAmount"
                value={values.accountPaymentAmount}
                onChange={handleChange}
              />
            </Form.Group>
            <Button type="submit">{t("salespagedetails.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSalesDetail;
