const sales = (state) => state.sales.sales;
const saleDetails = (state) => state.sales.saleDetails;
const saleTotals = (state) => state.sales.saleTotals;

// You could also create selectors with some logic inside
// const getWhereIAmFrom = (state) => state.cities.allCities.find(c => c.cityName === 'Belgrade');

export default {
  sales,
  saleDetails,
  saleTotals,
};
