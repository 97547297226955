// Use selectors to extract data
const dailyCutting = (state) => state.report.dailyCutting;
const dailyStatus = (state) => state.report.dailyStatus;
const dailyFinancial = (state) => state.report.dailyFinancial;
const weeklyCutting = (state) => state.report.weeklyCutting;
const monthlyCutting = (state) => state.report.monthlyCutting;
const monthlyTotalsCutting = (state) => state.report.monthlyTotalsCutting;
const financialCutting = (state) => state.report.financialCutting;
const expenses = (state) => state.report.expenses;

// You could also create selectors with some logic inside
// const getWhereIAmFrom = (state) => state.cities.allCities.find(c => c.cityName === 'Belgrade');

export default {
  dailyCutting,
  dailyStatus,
  dailyFinancial,
  weeklyCutting,
  monthlyCutting,
  monthlyTotalsCutting,
  financialCutting,
  expenses,
};
