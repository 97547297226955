import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import { useTranslation } from "react-i18next";

export const EditStock = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleEdit({
        stockid: props.row.stockid,
        speciesId: parseInt(dropdownValue),
        date: format(date, "yyyy-MM-dd"),
        morningStockRecovery: parseInt(values.morningStockRecovery),
        morningStockSale: parseInt(values.morningStockSale),
        sold: parseInt(values.sold),
        thrownAwayRecovery: parseInt(values.thrownAwayRecovery),
        thrownAwaySale: parseInt(values.thrownAwaySale),
        serviceToRecovery: parseInt(values.serviceToRecovery),
        recoveryToSale: parseInt(values.recoveryToSale),
        totalRecovery: parseInt(values.totalRecovery),
        totalSale: parseInt(values.totalSale),
      });
      event.preventDefault();
    }
    setValidated(false);
    clearValues();
  };

  const clearValues = () => {
    setDate(new Date());
    setDropdownValue();
    setValues({
      morningStockRecovery: 0,
      morningStockSale: 0,
      sold: 0,
      thrownAwayRecovery: 0,
      thrownAwaySale: 0,
      serviceToRecovery: 0,
      recoveryToSale: 0,
      totalRecovery: 0,
      totalSale: 0,
    });
  };

  //date picker
  const [date, setDate] = useState();

  //dropdown
  const [dropdownValue, setDropdownValue] = useState();
  const handleSelect = (e) => {
    setDropdownValue(e.target.value);
  };

  //other values
  const [values, setValues] = useState({
    morningStockRecovery: 0,
    morningStockSale: 0,
    sold: 0,
    thrownAwayRecovery: 0,
    thrownAwaySale: 0,
    serviceToRecovery: 0,
    recoveryToSale: 0,
    totalRecovery: 0,
    totalSale: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    props.row?.stockid &&
      setValues({
        morningStockRecovery: props.row?.morningstockrecovery,
        morningStockSale: props.row?.morningstocksale,
        sold: props.row?.sold,
        thrownAwayRecovery: props.row?.thrownawayrecovery,
        thrownAwaySale: props.row?.thrownawaysale,
        serviceToRecovery: props.row?.servicetorecovery,
        recoveryToSale: props.row?.recoverytosale,
        totalRecovery: props.row?.totalrecovery,
        totalSale: props.row?.totalsale,
      });
    props.row?.date && setDate(new Date(props.row.date));
    props.row?.speciesname &&
      props.species &&
      props.species.forEach((element) => {
        if (element.speciesname === props.row.speciesname)
          setDropdownValue(element.speciesid);
      });
  }, [props.row]);
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("statuspage.editmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("statuspage.title3")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSelect}
                value={dropdownValue}
              >
                <option>{t("statuspage.labelselect")}</option>
                {props.species &&
                  props.species.map((item, index) => {
                    return (
                      <option key={index} value={item.speciesid}>
                        {item.speciesname}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title2")}:</Form.Label>
              <DatePicker
                date={date}
                onDateChange={setDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title4")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="morningStockRecovery"
                value={values.morningStockRecovery}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title5")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="morningStockSale"
                value={values.morningStockSale}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title6")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="sold"
                value={values.sold}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title7")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="thrownAwayRecovery"
                value={values.thrownAwayRecovery}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title8")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="thrownAwaySale"
                value={values.thrownAwaySale}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title9")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="serviceToRecovery"
                value={values.serviceToRecovery}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title10")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="recoveryToSale"
                value={values.recoveryToSale}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title11")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="totalRecovery"
                value={values.totalRecovery}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("statuspage.title12")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="totalSale"
                value={values.totalSale}
                onChange={handleChange}
              />
            </Form.Group>
            <Button type="submit">{t("statuspage.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditStock;
