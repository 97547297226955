import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, InputGroup, Row } from "react-bootstrap";
import { enGB } from "date-fns/locale";
import {
  DateRangePicker,
  START_DATE,
  END_DATE,
  useDateInput,
} from "react-nice-dates";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export const DatePickerWithRange = (props) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (props.onChangeDate) {
      startDate &&
        !endDate &&
        props.onChangeDate(
          format(startDate, "yyyy-MM-dd"),
          format(startDate, "yyyy-MM-dd")
        );
      startDate &&
        endDate &&
        props.onChangeDate(
          format(startDate, "yyyy-MM-dd"),
          format(endDate, "yyyy-MM-dd")
        );
    }
  }, [startDate, endDate]);

  return (
    <>
      <div className="date-picker">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(date) => {
            setStartDate(date);
          }}
          onEndDateChange={(date) => {
            setEndDate(date);
          }}
          format="dd MMM yyyy"
          locale={enGB}
          maximumLength={props.disabled && -1}
        >
          {({ startDateInputProps, endDateInputProps, focus }) => (
            <div className="date-range">
              <Row className="g-2">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInput"
                    label={t("date.startdate")}
                    className="mb-3"
                    size="sm"
                  >
                    <Form.Control
                      size="sm"
                      className={
                        "input" + (focus === START_DATE ? " -focused" : "")
                      }
                      {...startDateInputProps}
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <span className="date-range_arrow" />

                  <FloatingLabel
                    controlId="floatingInput"
                    label={t("date.enddate")}
                    className="mb-3"
                    size="sm"
                  >
                    <Form.Control
                      disabled={props.disabled && true}
                      size="sm"
                      className={
                        "input" + (focus === END_DATE ? " -focused" : "")
                      }
                      {...endDateInputProps}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
          )}
        </DateRangePicker>
      </div>
    </>
  );
};

export default DatePickerWithRange;
