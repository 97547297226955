import React, { useEffect, useState } from "react";
import TableBS from "../Table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  sortUsers,
  getUsers,
  deleteUser,
  addUser,
  editUser,
  getUserRoles,
  changePassword,
} from "../../store/actions/actions";
import UserSelector from "../../store/selectors/userSelector";
import LoginSelector from "../../store/selectors/loginSelectors";
import EditUser from "./EditModal";
import DeleteUser from "./DeleteModal";
import AddUser from "./AddModal";
import Button from "react-bootstrap/Button";
import ChangePassword from "./ChangePassword";
import { Offcanvas, Toast, ToastContainer } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const User = (props) => {
  const { t } = useTranslation();

  const headers = [
    { title: t("userpage.title1"), id: "userid" },
    { title: t("userpage.title2"), id: "firstname" },
    { title: t("userpage.title3"), id: "lastname" },
    { title: t("userpage.title4"), id: "username" },
    { title: t("userpage.title5"), id: "email" },
    { title: t("userpage.title6"), id: "role" },
  ];
  //selected row
  const [row, setRow] = useState({});

  //edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (item) => {
    setRow(item);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);

  //delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleshowDeleteModal = (item) => {
    setRow(item);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  //add modal
  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false);

  //tableData
  const handleTableData = (sortedData) => {
    dispatch(sortUsers(sortedData));
  };

  //redux
  //use selector hook
  const { users, roles, changedPassMessage, roleName, userId } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      users: UserSelector.users(state),
      roles: UserSelector.roles(state),
      changedPassMessage: UserSelector.changedPassMessage(state),
      roleName: LoginSelector.roleName(state),
      userId: LoginSelector.userId(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getUserRoles());
  }, []);

  const deleteUserFromRedux = (userId) => {
    dispatch(deleteUser(userId));
    handleCloseDeleteModal();
  };

  const addUserFromRedux = (user) => {
    dispatch(addUser(user));
    handleCloseAddModal();
  };

  const editUserFromRedux = (user) => {
    dispatch(editUser(user));
    handleCloseEditModal();
  };

  //toast
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const changePasswordFromRedux = (oldPass, newPass) => {
    dispatch(changePassword(userId, oldPass, newPass));
    handleCloseCanvas();
    if (changedPassMessage) setShowToast(true);
  };

  //offcanvas
  const [showCanvas, setShowCanvas] = useState(false);
  const handleCloseCanvas = () => setShowCanvas(false);

  return (
    <>
      <div className="user">
        {roleName && roleName == "Administrator" && (
          <>
            <div className="bttns_inline">
              <Button
                variant="secondary"
                size="lg"
                onClick={() => setShowCanvas(true)}
              >
                {t("userpage.passbttn")}
              </Button>
              <Button
                variant="success"
                size="lg"
                onClick={() => setshowAddModal(true)}
              >
                {t("userpage.addbttn")}
              </Button>
            </div>
            <TableBS
              headers={headers}
              tableData={users && users}
              handleShowEditModal={(item) => handleShowEditModal(item)}
              handleshowDeleteModal={(item) => handleshowDeleteModal(item)}
              sortedData={(sortedData) => handleTableData(sortedData)}
            />
            <EditUser
              show={showEditModal}
              row={row}
              handleClose={handleCloseEditModal}
              handleEdit={(user) => editUserFromRedux(user)}
              roles={roles}
            />
            <DeleteUser
              show={showDeleteModal}
              handleClose={handleCloseDeleteModal}
              handleDelete={() => deleteUserFromRedux(row.userd)}
            />
            <AddUser
              show={showAddModal}
              handleClose={handleCloseAddModal}
              handleAdd={(user) => addUserFromRedux(user)}
              roles={roles}
            />
            <Offcanvas show={showCanvas} onHide={handleCloseCanvas}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("userpage.passbttn")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ChangePassword
                  handleChangePassword={(oldPass, newPass) =>
                    changePasswordFromRedux(oldPass, newPass)
                  }
                />
              </Offcanvas.Body>
            </Offcanvas>
          </>
        )}
        {roleName && roleName !== "Administrator" && (
          <ChangePassword
            handleChangePassword={(oldPass, newPass) =>
              changePasswordFromRedux(oldPass, newPass)
            }
          />
        )}
        {changedPassMessage && (
          <ToastContainer position="bottom-end">
            <Toast bg="light" show={showToast} onClose={toggleShowToast}>
              <Toast.Header>
                <strong className="me-auto">Password changed</strong>
                <small>now</small>
              </Toast.Header>
              <Toast.Body>{changedPassMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}
      </div>
    </>
  );
};

export default User;
