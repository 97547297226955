import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

export const EditSpecies = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleEdit({
        speciesId: props.row.speciesid,
        speciesName: speciesName,
      });
      event.preventDefault();
    }
    setValidated(false);
  };

  //speciesName
  const [speciesName, setSpeciesName] = useState("");
  const handleChange = (e) => {
    setSpeciesName(e.target.value);
  };

  useEffect(() => {
    props.row?.speciesname && setSpeciesName(props.row.speciesname);
  }, [props.row]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("speciespage.editmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("speciespage.label1")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.species")}
                value={speciesName}
                onChange={handleChange}
              />
            </Form.Group>
            <Button type="submit">{t("speciespage.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditSpecies;
