import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { DatePicker } from "react-nice-dates";
import { enGB } from "date-fns/locale";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export const EditCutting = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleEdit({
        cuttingid: props.row.cuttingid,
        speciesId: parseInt(dropdownValue),
        groupNumber: parseInt(groupValue),
        groupId: parseInt(dropdownValue),
        date: format(date, "yyyy-MM-dd"),
        noOfCuts: parseInt(numOfCuts),
        noOfParents: parseInt(numOfMothers),
        platform: platform,
        storeId: parseInt(storeValue),
        plantingDate: format(plantingDate, "yyyy-MM-dd"),
        comment: comment,
        treatment: treatment,
      });
      event.preventDefault();
    }
    setValidated(false);
  };

  //date picker
  const [date, setDate] = useState();

  //planting date
  const [plantingDate, setPlantingDate] = useState();

  //dropdown
  const [dropdownValue, setDropdownValue] = useState();
  const handleSelect = (e) => {
    setDropdownValue(e.target.value);
  };

  //stores ddl
  const [storeValue, setStoreValue] = useState();
  const handleStoreSelect = (e) => {
    setStoreValue(e.target.value);
  };

  //group ddl
  const [groupValue, setGroupValue] = useState();
  const handleGroupSelect = (e) => {
    setGroupValue(e.target.value);
  };

  //numberOfCuts
  const [numOfCuts, setNoOfCuts] = useState(0);
  const handleChange = (e) => {
    setNoOfCuts(e.target.value);
  };

  //numberOfMothers
  const [numOfMothers, setNoOfMothers] = useState(0);
  const handleMothersChange = (e) => {
    setNoOfMothers(e.target.value);
  };

  //comment
  const [comment, setComment] = useState("");
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  //platform
  const [platform, setPlatform] = useState("");
  const handlePlatformChange = (e) => {
    setPlatform(e.target.value);
  };

  //plant treatment
  const [treatment, setTreatment] = useState("");
  const handleTreatmentChange = (e) => {
    setTreatment(e.target.value);
  };

  useEffect(() => {
    props.row?.noofcuts && setNoOfCuts(props.row.noofcuts);
    props.row?.cuttingdate && setDate(new Date(props.row.cuttingdate));
    props.row?.speciesname &&
      props.species &&
      props.species.forEach((element) => {
        if (element.speciesname === props.row.speciesname)
          setDropdownValue(element.speciesid);
      });
    props.row?.store &&
      props.stores &&
      props.stores.forEach((element) => {
        if (element.storename === props.row.store)
          setStoreValue(element.storeid);
      });
    props.row?.noofparents && setNoOfMothers(props.row.noofparents);
    props.row?.platform && setPlatform(props.row.platform);
    props.row?.treatment && setTreatment(props.row.treatment);
    props.row?.plantingdate &&
      setPlantingDate(new Date(props.row.plantingdate));
    props.row?.commenttext && setComment(props.row.commenttext);
    props.row?.groupnumber && setGroupValue(props.row.groupnumber);
  }, [props.row]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("cuttingpage.editmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("cuttingpage.title3")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSelect}
                value={dropdownValue}
              >
                <option>{t("cuttingpage.label3select")}</option>
                {props.species &&
                  props.species.map((item, index) => {
                    return (
                      <option key={index} value={item.speciesid}>
                        {item.speciesname}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("cuttingpage.label1")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleGroupSelect}
                value={groupValue}
              >
                <option>{t("cuttingpage.label1select")}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("cuttingpage.label2")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                value={numOfCuts}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("cuttingpage.label3")}:</Form.Label>
              <DatePicker
                date={date}
                onDateChange={setDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("cuttingpage.label4")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                value={numOfMothers}
                onChange={handleMothersChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("cuttingpage.label5")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleStoreSelect}
                value={storeValue}
              >
                <option>{t("cuttingpage.label2select")}</option>
                {props.stores &&
                  props.stores.map((item, index) => {
                    return (
                      <option key={index} value={item.storeid}>
                        {item.storename}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("cuttingpage.title7")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.platform")}
                value={platform}
                onChange={handlePlatformChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("cuttingpage.title12")}:</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                placeholder={t("placeholders.treatment")}
                value={treatment}
                onChange={handleTreatmentChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("cuttingpage.title9")}:</Form.Label>
              <DatePicker
                date={plantingDate}
                onDateChange={setPlantingDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("cuttingpage.title11")}:</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                placeholder={t("placeholders.comment")}
                value={comment}
                onChange={handleCommentChange}
              />
            </Form.Group>
            <Button type="submit">{t("cuttingpage.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCutting;
