import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import { useTranslation } from "react-i18next";

export const EditServices = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleEdit({
        serviceId: props.row.serviceid,
        date: format(date, "yyyy-MM-dd"),
        // speciesId: parseInt(dropdownValue),
        // noOfCuts: parseInt(values.noOfCuts),
        noOfDay: parseInt(values.noOfDay),
        viewing: parseInt(values.viewing),
        removedNo: parseInt(values.removedNo),
        returnedNo: parseInt(values.returnedNo),
        thrownOutNo: parseInt(values.thrownOutNo),
      });
      event.preventDefault();
    }
    setValidated(false);
    clearValues();
  };

  const clearValues = () => {
    setDate(new Date());
    // setDropdownValue();
    setValues({
      noOfDay: 0,
      viewing: 0,
      removedNo: 0,
      returnedNo: 0,
      thrownOutNo: 0,
    });
  };

  //date picker
  const [date, setDate] = useState();

  //cutting date
  // const [cuttingDate, setCuttingDate] = useState(new Date());

  //dropdown
  // const [dropdownValue, setDropdownValue] = useState();
  // const handleSelect = (e) => {
  //   setDropdownValue(e.target.value);
  //   if (e.target.value === "0") {
  //     props.handleNumberOfCuts(0);
  //   } else {
  //     props.handleNumberOfCuts(e.target.value);
  //   }
  // };

  //dropdown for viewing
  const [dropdownViewValue, setDropdownViewValue] = useState();
  const handleViewSelect = (e) => {
    setDropdownViewValue(e.target.value);
  };

  //other values
  const [values, setValues] = useState({
    noOfDay: 0,
    viewing: 0,
    removedNo: 0,
    returnedNo: 0,
    thrownOutNo: 0,
  });

  //noofcuts
  // const [noOfCuts, setNoOfCuts] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    props.row?.serviceid &&
      setValues({
        noOfDay: props.row?.noofday,
        viewing: props.row?.viewing,
        removedNo: props.row?.removedno,
        returnedNo: props.row?.returnedno,
        thrownOutNo: props.row?.thrownoutno,
      });
    props.serviceDate && setDate(new Date(props.serviceDate));
    // props.row?.cuttingdate && setCuttingDate(new Date(props.row.cuttingdate));
    // props.row?.speciesname &&
    //   props.species &&
    //   props.species.forEach((element) => {
    //     if (element.speciesname === props.row.speciesname)
    //       setDropdownValue(element.speciesid);
    //   });
    props.row?.viewing && setDropdownViewValue(props.row.viewing);
  }, [props.row]);

  // useEffect(() => {
  //   setNoOfCuts(props.noofcuts);
  // }, [props.noofcuts]);

  // useEffect(() => {
  //   props.handleSpeciesAndGroups(format(cuttingDate, "yyyy-MM-dd"));
  //   props.handleNumberOfCuts(0);
  // }, [cuttingDate]);

  // useEffect(() => {
  //   props.serviceDate && setDate(new Date(props.serviceDate));
  // }, [props.serviceDate]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("servicepage.editmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("servicepage.title11")}:</Form.Label>
              <DatePicker
                date={date}
                onDateChange={setDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("servicepage.title2")}:</Form.Label>
              <DatePicker
                date={cuttingDate}
                onDateChange={setCuttingDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("servicepage.title12")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSelect}
                value={dropdownValue}
              >
                <option value="0">{t("servicepage.labelselect")}</option>
                {props.speciesandgroups &&
                  props.speciesandgroups.map((item, index) => {
                    return (
                      <option key={index} value={item.cuttingid}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("servicepage.title5")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="noOfCuts"
                value={noOfCuts}
                onChange={setNoOfCuts}
                disabled
              />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("servicepage.title6")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="noOfDay"
                value={values.noOfDay}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("servicepage.title7")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleViewSelect}
                value={dropdownViewValue}
              >
                <option>{t("servicepage.labelselect1")}</option>
                <option value="Prvo">Prvo</option>
                <option value="Drugo">Drugo</option>
                <option value="Trece">Trece</option>
                <option value="Zadnje">Zadnje</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("servicepage.title8")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="removedNo"
                value={values.removedNo}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("servicepage.title9")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="returnedNo"
                value={values.returnedNo}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("servicepage.title10")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="thrownOutNo"
                value={values.thrownOutNo}
                onChange={handleChange}
              />
            </Form.Group>
            <Button type="submit">{t("servicepage.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditServices;
