// Use POEditor or something similar to manage translations

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enTexts from "./locales/en.json";
import rsTexts from "./locales/rs.json";

export const resources = {
  en: {
    translation: enTexts,
  },
  rs: {
    translation: rsTexts,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ["en", "rs"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
