import React, { useEffect, useState } from "react";
import TableBS from "../Table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  sortServices,
  getServices,
  deleteServices,
  addServices,
  editServices,
  getSpecies,
  getSpeciesAndGroups,
  getCuts,
} from "../../store/actions/actions";
import ServicesSelector from "../../store/selectors/serviceSelector";
import SpeciesSelector from "../../store/selectors/speciesSelector";
import EditServices from "./EditModal";
import DeleteServices from "./DeleteModal";
import AddServices from "./AddModal";
import Button from "react-bootstrap/Button";
import DatePickerWithRange from "../DatePicker";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export const Service = (props) => {
  const { t } = useTranslation();

  const headers = [
    { title: t("servicepage.title1"), id: "serviceid" },
    { title: t("servicepage.title4"), id: "speciesname" },
    { title: t("servicepage.title2"), id: "cuttingdate" },
    { title: t("servicepage.title5"), id: "noofcuts" },
    { title: t("servicepage.title6"), id: "noofday" },
    { title: t("servicepage.title7"), id: "viewing" },
    { title: t("servicepage.title8"), id: "removedno" },
    { title: t("servicepage.title9"), id: "returnedno" },
    { title: t("servicepage.title10"), id: "thrownoutno" },
  ];

  //selected row
  const [row, setRow] = useState({});

  //edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (item) => {
    setRow(item);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);

  //delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleshowDeleteModal = (item) => {
    setRow(item);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  //add modal
  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false);

  //tableData
  const handleTableData = (sortedData) => {
    dispatch(sortServices(sortedData));
  };

  //redux
  //use selector hook
  const { services, species, speciesandgroups, noofcuts } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      services: ServicesSelector.services(state),
      species: SpeciesSelector.species(state),
      speciesandgroups: ServicesSelector.speciesandgroups(state),
      noofcuts: ServicesSelector.noofcuts(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

  useEffect(() => {
    dispatch(getServices(startDate, endDate));
    dispatch(getSpecies());
  }, []);

  // ...and than map your thunks bellow
  const getServicesFromRedux = (startDate, endDate) => {
    dispatch(getServices(startDate, endDate));
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const deleteServicesFromRedux = (serviceId) => {
    dispatch(deleteServices(serviceId));
    handleCloseDeleteModal();
  };

  const addServicesFromRedux = (services) => {
    dispatch(addServices(services, startDate, endDate));
    handleCloseAddModal();
  };

  const editServicesFromRedux = (services) => {
    dispatch(editServices(services, startDate, endDate));
    handleCloseEditModal();
  };

  const getSpeciesAndGroupsRedux = (cuttingDate) => {
    dispatch(getSpeciesAndGroups(cuttingDate));
  };

  const getCutsRedux = (cuttingId) => {
    dispatch(getCuts(cuttingId));
  };

  return (
    <>
      <div className="services">
        <div className="services_start_page">
          <div className="bttns_inline" onClick={() => setshowAddModal(true)}>
            <Button variant="success" size="lg">
              {t("servicepage.addbttn")}
            </Button>
          </div>
          <DatePickerWithRange
            onChangeDate={(startDate, endDate) =>
              getServicesFromRedux(startDate, endDate)
            }
          />
        </div>
        <TableBS
          headers={headers}
          tableData={services && services}
          handleShowEditModal={(item) => handleShowEditModal(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModal(item)}
          sortedData={(sortedData) => handleTableData(sortedData)}
        />
        <EditServices
          show={showEditModal}
          row={row}
          handleClose={handleCloseEditModal}
          handleEdit={(services) => editServicesFromRedux(services)}
          species={species}
          speciesandgroups={speciesandgroups}
          noofcuts={noofcuts}
          handleSpeciesAndGroups={(cuttingDate) =>
            getSpeciesAndGroupsRedux(cuttingDate)
          }
          handleNumberOfCuts={(cuttingId) => getCutsRedux(cuttingId)}
          serviceDate={startDate}
        />
        <DeleteServices
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={() => deleteServicesFromRedux(row.serviceid)}
        />
        <AddServices
          show={showAddModal}
          handleClose={handleCloseAddModal}
          handleAdd={(services) => addServicesFromRedux(services)}
          species={species}
          speciesandgroups={speciesandgroups}
          noofcuts={noofcuts}
          handleSpeciesAndGroups={(cuttingDate) =>
            getSpeciesAndGroupsRedux(cuttingDate)
          }
          handleNumberOfCuts={(cuttingId) => getCutsRedux(cuttingId)}
          serviceDate={startDate}
        />
      </div>
    </>
  );
};

export default Service;
