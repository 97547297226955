import Login from "./components/LoginPage/Login";
import Home from "./components/HomePage/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cutting from "./components/CuttingPage/Cutting";
import Services from "./components/ServicePage/Service";
import Store from "./components/StorePage/Store";
import Sales from "./components/SalesPage/Sales";
import Species from "./components/SpeciesPage/Species";
import Report from "./components/ReportPage/Report";
import User from "./components/UserAdministration/User";
import Navbar from "./components/Navbar/Navbar";
import PlantStatus from "./components/PlantStatusPage/PlantStatus";
import { useSelector, shallowEqual } from "react-redux";
import LoginSelector from "./store/selectors/loginSelectors";
import Demo from "./components/CalendarPage/CalendarPage";
import Calendar from "./components/CalendarPage/CalendarPage";
import Orders from "./components/OrdersPage/Orders";

function App() {
  //redux
  //use selector hook
  const { isValid } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      isValid: LoginSelector.isValidLogin(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );

  return (
    <Router>
      {isValid && <Navbar />}
      <Routes>
        {!isValid ? (
          <Route path="/" element={<Login />} />
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/cutting" element={<Cutting />} />
            <Route path="/services" element={<Services />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/species" element={<Species />} />
            <Route path="/reports" element={<Report />} />
            <Route path="/users" element={<User />} />
            <Route path="/status" element={<PlantStatus />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/calendar" element={<Calendar />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
