const initialState = {
  sales: [],
  saleDetails: [],
  saleTotals: [],
};

const salesReducer = (state = initialState, action) => {
  if (action.type === "GET_SALE") {
    return {
      ...state,
      sales: action.sales,
    };
  } else if (action.type === "SORTED_SALE") {
    return {
      ...state,
      sales: [...action.sortedData],
    };
  } else if (action.type === "DELETE_SALE") {
    return {
      ...state,
      sales: state.sales.filter((item) => item.saleid !== action.saleId),
    };
  } else if (action.type === "GET_SALE_DETAILS") {
    return {
      ...state,
      saleDetails: action.saleDetails,
    };
  } else if (action.type === "SORTED_SALE_DETAILS") {
    return {
      ...state,
      saleDetails: [...action.sortedData],
    };
  } else if (action.type === "DELETE_SALE_DETAILS") {
    return {
      ...state,
      saleDetails: state.saleDetails.filter(
        (item) => item.saledetailsid !== action.saleDetailsId
      ),
    };
  } else if (action.type === "GET_SALE_TOTALS") {
    return {
      ...state,
      saleTotals: action.saleTotals,
    };
  } else {
    return state;
  }
};

export default salesReducer;
