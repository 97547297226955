import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { NavbarItems } from "../../Data/NavbarItems";
import logoImg from "../../assets/logo/wunderbaum-logo.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LoginSelector from "../../store/selectors/loginSelectors";
import { logout } from "../../store/actions/actions";
import { useTranslation } from "react-i18next";

export const Navbar = (props) => {
  //language area
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    if (lng === "en") setIsEng(true);
    else setIsEng(false);
  };

  const handleLanguageSwitch = (value) => () => changeLanguage(value);

  const [isEng, setIsEng] = useState(true);

  //sidebar area
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => {
    if (window.innerWidth <= 960) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  };

  const toogleSidebar = () => {
    setSidebar(!sidebar);
  };

  useEffect(() => {
    showSidebar();

    if (i18n.language === "en") setIsEng(true);
    else setIsEng(false);
  }, []);
  window.addEventListener("resize", showSidebar);

  //redux
  //use selector hook
  const { pages } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      pages: LoginSelector.pages(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const handelLogout = () => {
    dispatch(logout(false));
  };

  return (
    <>
      {!sidebar && (
        <div className="navbar">
          <Link to="#" className="menu_bars">
            <i className="fas fa-bars fa-lg" onClick={toogleSidebar}></i>
          </Link>
        </div>
      )}

      <nav className={sidebar ? "nav_menu active" : "nav_menu"}>
        <ul className="nav_menu_items" onClick={showSidebar}>
          <li className="navbar-toggle">
            <Link to="/">
              <img src={logoImg}></img>
            </Link>
          </li>
          {pages &&
            pages.map((item, index) => {
              return (
                <li key={index} className="nav_text">
                  <NavLink
                    to={item.path}
                    className={(item) => (item.isActive ? "active" : "")}
                  >
                    <span>{isEng ? item.name : item.namers}</span>
                  </NavLink>
                </li>
              );
            })}

          <div className="nav_menu_bottom">
            <li className="nav_language">
              <div
                className={
                  isEng ? "nav_language_div active" : "nav_language_div"
                }
                onClick={handleLanguageSwitch("en")}
              >
                ENG
              </div>
              <div>|</div>
              <div
                className={
                  isEng ? "nav_language_div" : "nav_language_div active"
                }
                onClick={handleLanguageSwitch("rs")}
              >
                RS
              </div>
            </li>
            {NavbarItems.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <i className="fas fa-sign-out-alt fa-lg"></i>
                  <NavLink
                    onClick={handelLogout}
                    to={item.path}
                    className={(item) => (item.isActive ? "" : "")}
                  >
                    <span>{isEng ? item.name : item.namers}</span>
                  </NavLink>
                </li>
              );
            })}
          </div>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
