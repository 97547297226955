import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import { useTranslation } from "react-i18next";

export const EditSalesTotals = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleEdit({
        saleTotalId: props.row.saleTotalId,
        date: format(date, "yyyy-MM-dd"),
        totalSoldCount: parseInt(values.totalSoldCount),
        totalAmount: parseFloat(values.totalAmount),
        totalCashPaymentAmount: parseFloat(values.totalCashPaymentAmount),
        totalAccountPaymentAmount: parseFloat(values.totalAccountPaymentAmount),
      });
      event.preventDefault();
    }
    setValidated(false);
    clearValues();
  };

  const clearValues = () => {
    setDate(new Date());
    setValues({
      totalSoldCount: 0,
      totalAmount: 0,
      totalCashPaymentAmount: 0,
      totalAccountPaymentAmount: 0,
    });
  };

  //date picker
  const [date, setDate] = useState(new Date());

  //other values
  const [values, setValues] = useState({
    totalSoldCount: 0,
    totalAmount: 0,
    totalCashPaymentAmount: 0,
    totalAccountPaymentAmount: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    props.row?.saleTotalId &&
      setValues({
        totalSoldCount: props.row?.totalSoldCount,
        totalAmount: props.row?.totalAmount,
        totalCashPaymentAmount: props.row?.totalCashPaymentAmount,
        totalAccountPaymentAmount: props.row?.totalAccountPaymentAmount,
      });
    props.row?.date && setDate(new Date(props.row.date));
  }, [props.row]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("salespagetotals.editmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagetotals.title2")}:</Form.Label>
              <DatePicker
                date={date}
                onDateChange={setDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagetotals.title3")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="totalSoldCount"
                value={values.totalSoldCount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagetotals.title4")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="totalAmount"
                value={values.totalAmount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagetotals.title5")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="totalCashPaymentAmount"
                value={values.totalCashPaymentAmount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("salespagetotals.title6")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="totalAccountPaymentAmount"
                value={values.totalAccountPaymentAmount}
                onChange={handleChange}
              />
            </Form.Group>
            <Button type="submit">{t("salespagetotals.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditSalesTotals;
