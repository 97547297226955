const initialState = {
  stores: [],
};

const storeReducer = (state = initialState, action) => {
  if (action.type === "GET_STORES") {
    return {
      ...state,
      stores: action.stores,
    };
  } else if (action.type === "SORTED_STORES") {
    return {
      ...state,
      stores: [...action.sortedData],
    };
  } else if (action.type === "DELETE_STORE") {
    return {
      ...state,
      stores: state.stores.filter((item) => item.storeid !== action.storeId),
    };
  } else {
    return state;
  }
};

export default storeReducer;
