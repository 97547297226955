const initialState = {
  stocks: [],
};

const statusReducer = (state = initialState, action) => {
  if (action.type === "GET_STOCKS") {
    return {
      ...state,
      stocks: action.stocks,
    };
  } else if (action.type === "SORTED_STOCKS") {
    return {
      ...state,
      stocks: [...action.sortedData],
    };
  } else if (action.type === "DELETE_STOCKS") {
    return {
      ...state,
      stocks: state.stocks.filter((item) => item.stockid !== action.stockId),
    };
  } else {
    return state;
  }
};

export default statusReducer;
