import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";

export const AddOrder = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleAdd({
        speciesId: parseInt(dropdownValue),
        date: format(date, "yyyy-MM-dd"),
        pickupDate: format(pickupDate, "yyyy-MM-dd"),
        customerCode: values.customerCode,
        quantity: parseInt(values.quantity),
        comment: values.comment,
        total: parseFloat(values.total),
      });
      event.preventDefault();
    }
    setValidated(false);
    clearValues();
  };

  const clearValues = () => {
    setDate(new Date());
    setPickupDate(new Date());
    setDropdownValue();
    setValues({
      customerCode: "",
      quantity: 0,
      comment: "",
      total: 0,
    });
  };

  //date picker
  const [date, setDate] = useState(new Date());
  const [pickupDate, setPickupDate] = useState(new Date());

  //dropdown
  const [dropdownValue, setDropdownValue] = useState();
  const handleSelect = (e) => {
    setDropdownValue(e.target.value);
  };

  //other values
  const [values, setValues] = useState({
    customerCode: "",
    quantity: 0,
    comment: "",
    total: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("orderspage.addmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("orderspage.title2")}:</Form.Label>
              <DatePicker
                date={date}
                onDateChange={setDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("orderspage.title8")}:</Form.Label>
              <DatePicker
                date={pickupDate}
                onDateChange={setPickupDate}
                locale={enGB}
                format="yyyy-MM-dd"
              >
                {({ inputProps, focused }) => (
                  <Form.Control
                    className={"input" + (focused ? " -focused" : "")}
                    {...inputProps}
                  />
                )}
              </DatePicker>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("orderspage.title3")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.code")}
                name="customerCode"
                value={values.customerCode}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("orderspage.title4")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSelect}
              >
                <option>{t("orderspage.label1select")}</option>
                {props.species &&
                  props.species.map((item, index) => {
                    return (
                      <option key={index} value={item.speciesid}>
                        {item.speciesname}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("orderspage.title5")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="quantity"
                value={values.quantity}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("orderspage.title6")}:</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                placeholder={t("placeholders.comment")}
                name="comment"
                value={values.comment}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("orderspage.title7")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.number")}
                name="total"
                value={values.total}
                onChange={handleChange}
              />
            </Form.Group>
            <Button type="submit">{t("storepage.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddOrder;
