import React from "react";

export const NavbarItems = [
  {
    name: "Log Out",
    namers: "Odjavi se",
    path: "/#",
    cName: "nav_text",
  },
];

export default NavbarItems;
