const initialState = {
  users: [],
  roles: [],
  changedPassMessage: "Test",
};

const userReducer = (state = initialState, action) => {
  if (action.type === "GET_USERS") {
    return {
      ...state,
      users: action.users,
    };
  } else if (action.type === "SORTED_USERS") {
    return {
      ...state,
      users: [...action.sortedData],
    };
  } else if (action.type === "DELETE_USER") {
    return {
      ...state,
      users: state.users.filter((item) => item.userid !== action.userId),
    };
  } else if (action.type === "GET_USER_ROLES") {
    return {
      ...state,
      roles: action.roles,
    };
  } else if (action.type === "CHANGE_PASSWORD") {
    return {
      ...state,
      changedPassMessage: action.message,
    };
  } else {
    return state;
  }
};

export default userReducer;
