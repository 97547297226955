import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  getdailyCuttingReport,
  getdailyStateReport,
  getdailyFinancialReport,
  getweeklyCuttingReport,
  getmonthlyCuttingReport,
  getmonthlyCuttingTotalsReport,
  getmonthlyFinancialReport,
  getmonthlyExpensesReport,
  getStores,
} from "../../store/actions/actions";
import ReportSelector from "../../store/selectors/reportSelector";
import StoreSelector from "../../store/selectors/storeSelector";
import { useTranslation } from "react-i18next";
import DatePickerWithRange from "../DatePicker";
import format from "date-fns/format";
import { Form } from "react-bootstrap";
import "jspdf-autotable";

export const Report = (props) => {
  const { t } = useTranslation();
  //redux
  //use selector hook
  const {
    dailyCutting,
    dailyStatus,
    dailyFinancial,
    weeklyCutting,
    monthlyCutting,
    monthlyTotalsCutting,
    financialCutting,
    expenses,
    stores,
  } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      dailyCutting: ReportSelector.dailyCutting(state),
      dailyStatus: ReportSelector.dailyStatus(state),
      dailyFinancial: ReportSelector.dailyFinancial(state),
      weeklyCutting: ReportSelector.weeklyCutting(state),
      monthlyCutting: ReportSelector.monthlyCutting(state),
      monthlyTotalsCutting: ReportSelector.monthlyTotalsCutting(state),
      financialCutting: ReportSelector.financialCutting(state),
      expenses: ReportSelector.expenses(state),
      stores: StoreSelector.stores(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );

  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));

  //dropdown
  const [dropdownValue, setDropdownValue] = useState();
  const handleSelect = (e) => {
    setDropdownValue(e.target.value);
  };

  //report ddl
  const [reportValue, setReportValue] = useState();
  const handleSelectReport = (e) => {
    setReportValue(e.target.value);
  };

  useEffect(() => {
    dispatch(getdailyCuttingReport(startDate));
    dispatch(getdailyStateReport(startDate));
    dispatch(getdailyFinancialReport(startDate));
    dispatch(getweeklyCuttingReport(startDate));
    dispatch(getmonthlyFinancialReport(startDate));
    dispatch(getmonthlyExpensesReport(startDate));
    dispatch(getStores());
  }, []);
/*
  useEffect(() => {
    if (dropdownValue && parseInt(dropdownValue) != 0) {
      dispatch(getmonthlyCuttingReport(startDate, parseInt(dropdownValue)));
      dispatch(
        getmonthlyCuttingTotalsReport(startDate, parseInt(dropdownValue))
      );
    }
  }, [dropdownValue]);
  */

  const getDataWhenDateChanged = (startDate) => {
    dispatch(getdailyCuttingReport(startDate));
    dispatch(getdailyStateReport(startDate));
    dispatch(getdailyFinancialReport(startDate));
    dispatch(getweeklyCuttingReport(startDate));
    dispatch(getmonthlyFinancialReport(startDate));
    dispatch(getmonthlyCuttingReport(startDate));
    dispatch(getmonthlyCuttingTotalsReport(startDate));
    dispatch(getmonthlyExpensesReport(startDate));
    /*
    if (dropdownValue && parseInt(dropdownValue) != 0) {
      dispatch(getmonthlyCuttingReport(startDate, parseInt(dropdownValue)));
      dispatch(
        getmonthlyCuttingTotalsReport(startDate, parseInt(dropdownValue))
      );
    }
    */
    setStartDate(startDate);
  };

  const handleExportReport = () => {
    switch (reportValue) {
      case "daily":
        exportDailyReport();
        break;
      case "weekly":
        exportWeeklyReport();
        break;
      case "turnout":
        exportMonthlyReport();
        break;
      case "financial":
        exportMonthlyFinancialReport();
        break;
      case "expenses":
        exportMonthlyExpensesReport();
        break;
      default:
        break;
    }
  };

  const exportDailyReport = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = "DNEVNI IZVESTAJ ZA " + startDate;
    let titleXOffset = getCenterOffset(doc, title);
    doc.text(title, titleXOffset, 20);

    let yPosition = 50;

    const cuttingHeaders = [
      [
        "Vrsta",
        "Grupa",
        "Platforma",
        "Zasadjene",
        "Broj majki",
        "Broj secenja",
        "Prosecan broj secenja",
      ],
    ];

    dailyCutting.forEach((store) => {
      let data = store.records.map((cutting) => [
        cutting.species,
        cutting.group,
        cutting.platform,
        cutting.plantingDate,
        cutting.parents,
        cutting.cuts,
        cutting.averageCuts,
      ]);

      doc.text(
        store.storeName + ":",
        getCenterOffset(doc, store.storeName),
        yPosition
      );

      yPosition += 15;

      let content = {
        startY: yPosition,
        head: cuttingHeaders,
        body: data,
        styles: { halign: "center" },
      };

      doc.autoTable(content);

      yPosition += (store.records.length + 1) * 25 + 30;
    });

    yPosition += 30;

    //total
    const sum = dailyCutting.map((item) =>
      item.records.reduce((total, a) => total + a.cuts, 0)
    );
    doc.text("Total : " + sum[0], marginLeft, yPosition);

    yPosition += 30;

    doc.text("STANJE:", getCenterOffset(doc, "STANJE:"), yPosition);

    yPosition += 15;

    const stateHeaders = [["Datum", "U prodaji"]];

    let stateData = [[dailyStatus.date, dailyStatus.totalsale]];

    let stateContent = {
      startY: yPosition,
      head: stateHeaders,
      body: stateData,
      styles: { halign: "center" },
    };

    doc.autoTable(stateContent);

    yPosition += 100;

    doc.text("PRODAJA:", getCenterOffset(doc, "PRODAJA:"), yPosition);

    yPosition += 15;

    const financialHeaders = [["Prodato", "Pare"]];

    let financialData = [[dailyFinancial.soldcount, dailyFinancial.amount]];

    let financialContent = {
      startY: yPosition,
      head: financialHeaders,
      body: financialData,
      styles: { halign: "center" },
    };

    doc.autoTable(financialContent);

    doc.save("Dnevni izvestaj - " + startDate + ".pdf");
  };

  const getCenterOffset = (doc, text) => {
    return Math.round(
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2,
      0
    );
  };

  const exportWeeklyReport = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    var endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 7);
    const title =
      "NEDELJNI IZVESTAJ OD " +
      startDate +
      " DO " +
      endDate.toISOString().slice(0, 10);
    let titleXOffset = getCenterOffset(doc, title);
    doc.text(title, titleXOffset, 20);

    let yPosition = 50;

    /*

    const cuttingHeaders = [["Vrsta", "Ukupno seceno", "Prosecno seceno"]];

    weeklyCutting.forEach((store) => {
      let data = store.records.map((cutting) => [
        cutting.species,
        cutting.noOfCuts,
        cutting.averageCuts,
      ]);
      data.push(["TOTAL", store.totalCuts, store.totalAverageCuts]);

      doc.text(
        store.storeName + ":",
        getCenterOffset(doc, store.storeName),
        yPosition
      );

      yPosition += 15;

      let content = {
        startY: yPosition,
        head: cuttingHeaders,
        body: data,
        styles: { halign: "center" },
      };

      doc.autoTable(content);

      yPosition += (store.records.length + 1) * 25 + 30;
    });
    */

    // NOVO

    const cuttingHeaders = [["Vrsta", "Ukupno seceno", "Prosecno seceno"]];

    let data = weeklyCutting.records.map((cutting) => [
      cutting.species,
      cutting.noOfCuts,
      cutting.averageCuts,
    ]);

    data.push([
      {content: "TOTAL", styles: { fontStyle: "bold" }},
      {content: weeklyCutting.totalCuts, styles: { fontStyle: "bold" } },
      {content: weeklyCutting.totalAverageCuts, styles: { fontStyle: "bold" }}
    ]);

      let content = {
        startY: yPosition,
        head: cuttingHeaders,
        body: data,
        styles: { halign: "center" },
      };

      doc.autoTable(content);

    // KRAJ NOVOG

    doc.save(
      "Nedeljni izvestaj - " +
        startDate +
        "_" +
        endDate.toISOString().slice(0, 10) +
        ".pdf"
    );
  };

  const exportMonthlyReport = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let yPosition = 50;

    const monthlyCuttingHeaders = [
      [
        "Datum",
        "Lokal",
        "Vrsta",
        "Grupa",
        "Broj majki",
        "Seceno komada",
        "Prosek po majci",
        "Izlaznost",
        "Zasadjene",
        "Izaslo komada",
      ],
    ];

    let cuttingData = monthlyCutting.records.map((cutting) => [
      cutting.date,
      cutting.store,
      cutting.species,
      cutting.group,
      cutting.noOfParentPlants,
      cutting.noOfCuts,
      cutting.averageCutsPerParent,
      cutting.turnoutAverage,
      cutting.plantingDate,
      cutting.turnout,
    ]);

    let cuttingContent = {
      startY: yPosition,
      head: monthlyCuttingHeaders,
      body: cuttingData,
      styles: { halign: "center" },
    };

    doc.autoTable(cuttingContent);

    yPosition += (monthlyCutting.records.length + 1) * 25 + 30;

    const cuttingTotalsHeaders = [
      [
        "Vrsta",
        "Ukupno seceno",
        "Prosecno seceno po danu",
        "Prosecno seceno po majci",
        "Ukupno izaslo",
        "Prosecna izlaznost",
      ],
    ];

    let cuttingTotalData = monthlyTotalsCutting.records.map((x) => [
      x.species,
      x.noOfCuts,
      x.averageCutsPerDay,
      x.averageCutsPerParent,
      x.totalTurnout,
      x.averageTurnout,
    ]);

    cuttingTotalData.push([
      {content: "TOTAL", styles: { fontStyle: "bold" }},
      {content: monthlyTotalsCutting.totalCuts, styles: { fontStyle: "bold" } },
      {content: monthlyTotalsCutting.totalCutsPerDay, styles: { fontStyle: "bold" }},
      {content: monthlyTotalsCutting.totalCutsPerParent, styles: { fontStyle: "bold" } },
      {content: monthlyTotalsCutting.totalTurnout, styles: { fontStyle: "bold" } },
      {content: monthlyTotalsCutting.averageTurnout, styles: { fontStyle: "bold" } }
    ]);

    let cuttingTotalContent = {
      startY: yPosition,
      head: cuttingTotalsHeaders,
      body: cuttingTotalData,
      styles: { halign: "center" },
    };

    doc.autoTable(cuttingTotalContent);

    doc.save("Monthly turnout report - " + startDate + ".pdf");
  };

  const exportMonthlyFinancialReport = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    let yPosition = 50;

    const headers = [
      [
        "Datum",
        "Prodato komada",
        "Prodaja u EUR",
        "Isplate crno",
        "Isplate belo",
        "Stanje c. pare",
        "Uplaceno na racun",
        "Na racunu",
        "Dug/uplata",
      ],
    ];

    let data = financialCutting.records.map((x) => [
      x.date,
      x.soldCount,
      x.amount,
      x.cashPaymentAmount,
      x.accountPaymentAmount,
      x.cashBalance,
      x.paymentToAccount,
      x.accountBalance,
      x.comment,
    ]);

    // data.push([
    //   "TOTAL",
    //   financialCutting.totalSoldCount,
    //   financialCutting.totalAmount,
    //   financialCutting.totalCashPaymentAmount,
    //   financialCutting.totalAccountPaymentAmount,
    //   financialCutting.cashBalance,
    //   financialCutting.paymentToAccount,
    //   financialCutting.accountBalance,
    //   "",
    // ]);

    let content = {
      startY: yPosition,
      head: headers,
      body: data,
      styles: { halign: "center" },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 1,
    };

    doc.autoTable(content);

    //Totali
    yPosition += (data.length + 1) * 25 + 30;

    let footerData = [];
    footerData.push([{content: financialCutting.period + ":", styles: { fontStyle: "bold"}}]);
    footerData.push([{ content: "UKUPNO JE PRODATO " + financialCutting.totalSoldCount + " KOMADA;"}]);
    footerData.push([{ content: "UKUPNI PRIHODI SU " + financialCutting.totalAmount + " EUR;"}]);
    footerData.push(["UKUPNI RASHODI SU " + financialCutting.expenses + " EUR;"]);
    footerData.push(["U KESU JE OSTALO "+ financialCutting.cashBalance + " EUR;"]);
    footerData.push(["NA RACUNU JE OSTALO "+ financialCutting.accountBalance + " EUR;"]);

    let footerContent = {
      startY: yPosition,
      body: footerData,
      styles: { halign: "center" },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 1,
    };
    
    doc.autoTable(footerContent);

    doc.save("Monthly financial report - " + financialCutting.period + ".pdf");
  };

  // const [key, setKey] = useState("daily");
  // const [testData, setTestData] = useState({
  //   people: [
  //     { name: "Keanu Reeves", profession: "Actor" },
  //     { name: "Lionel Messi", profession: "Football Player" },
  //     { name: "Cristiano Ronaldo", profession: "Football Player" },
  //     { name: "Jack Nicklaus", profession: "Golf Player" },
  //   ],
  // });

  // const exportPDF = () => {
  //   const unit = "pt";
  //   const size = "A4"; // Use A1, A2, A3 or A4
  //   const orientation = "portrait"; // portrait or landscape

  //   const marginLeft = 40;
  //   const doc = new jsPDF(orientation, unit, size);

  //   doc.setFontSize(15);

  //   const title = "My Awesome Report";
  //   const headers = [["NAME", "PROFESSION"]];

  //   const data = testData.people.map((elt) => [elt.name, elt.profession]);

  //   let content = {
  //     startY: 50,
  //     head: headers,
  //     body: data,
  //   };

  //   doc.text(title, marginLeft, 40);
  //   doc.autoTable(content);
  //   doc.save("report.pdf");
  // };

  const exportMonthlyExpensesReport = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    console.log(expenses);

    var endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 7);
    const title =
      "IZVESTAJ TROSKOVA OD " +
      expenses.startDate.slice(0, 10) +
      " DO " +
      expenses.endDate.slice(0, 10);
    let titleXOffset = getCenterOffset(doc, title);
    doc.text(title, titleXOffset, 20);

    let yPosition = 50;

    const cuttingHeaders = [["Datum", "Opis", "Iznos", "Tip"]];

    let data = expenses.records.map((expense) => [
      expense.date,
      expense.description,
      expense.amount,
      expense.type
    ]);

    data.push([
      {content: "TOTAL KES", styles: { fontStyle: "bold" }},
      {content: "" },
      {content: expenses.totalCash, styles: { fontStyle: "bold" }},
      {content: "" }
    ]);

    data.push([
      {content: "TOTAL RACUN", styles: { fontStyle: "bold" }},
      {content: "" },
      {content: expenses.totalAccount, styles: { fontStyle: "bold" }},
      {content: "" }
    ]);

      let content = {
        startY: yPosition,
        head: cuttingHeaders,
        body: data,
        styles: { halign: "center" },
      };

      doc.autoTable(content);

    doc.save(
      "Mesecni izvestaj troskova - " +
        expenses.startDate.slice(0, 10) +
        "_" +
        expenses.endDate.slice(0, 10) +
        ".pdf"
    );
  };

  return (
    <>
      <div className="report">
        <div className="cutting_start_page">
          <DatePickerWithRange
            onChangeDate={(startDate, endDate) =>
              getDataWhenDateChanged(startDate)
            }
            disabled={true}
          />
        </div>
        <div className="report_content">
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label>{t("reportpage.title1")}:</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={handleSelectReport}
            >
              <option>{t("reportpage.label1select")}</option>
              <option value="daily">{t("reportpage.report1")}</option>
              <option value="weekly">{t("reportpage.report2")}</option>
              <option value="turnout">{t("reportpage.report3")}</option>
              <option value="financial">{t("reportpage.report4")}</option>
              <option value="expenses">{t("reportpage.report5")}</option>
            </Form.Select>
          </Form.Group>
          {/*
          {reportValue === "turnout" && (
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("reportpage.title2")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSelect}
              >
                <option value="0">{t("reportpage.label2select")}</option>
                {stores &&
                  stores.map((item, index) => {
                    return (
                      <option key={index} value={item.storeid}>
                        {item.storename}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          )}
                */}
          <Button variant="danger" onClick={handleExportReport}>
            Export as PDF
          </Button>
        </div>
      </div>
    </>
  );
};

export default Report;
