import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { loadState, saveState } from "./localStorage";
import throttle from "lodash.throttle";

const persistedState = loadState();
export default function configureStore() {
  const middlewareEnhancer = applyMiddleware(thunk);

  const store = createStore(rootReducer, persistedState, middlewareEnhancer);

  store.subscribe(
    throttle(() => {
      saveState({
        login: store.getState().login,
      });
    }, 1000)
  );

  return store;
}
