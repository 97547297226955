const initialState = {
  orders: [],
};

const ordersReducer = (state = initialState, action) => {
  if (action.type === "GET_ORDERS") {
    return {
      ...state,
      orders: action.orders,
    };
  } else if (action.type === "SORTED_ORDERS") {
    return {
      ...state,
      orders: [...action.sortedData],
    };
  } else if (action.type === "DELETE_ORDERS") {
    return {
      ...state,
      orders: state.orders.filter((item) => item.orderid !== action.orderId),
    };
  } else {
    return state;
  }
};

export default ordersReducer;
