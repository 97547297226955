const initialState = {
  dailyCutting: [],
  dailyStatus: [],
  dailyFinancial: [],
  weeklyCutting: [],
  monthlyCutting: [],
  monthlyTotalsCutting: [],
  financialCutting: [],
  expenses: [],
};

const reportReducer = (state = initialState, action) => {
  if (action.type === "GET_DAILY_CUTTING") {
    return {
      ...state,
      dailyCutting: action.cutting,
    };
  } else if (action.type === "GET_DAILY_STATUS") {
    return {
      ...state,
      dailyStatus: action.cuttingStatus,
    };
  } else if (action.type === "GET_DAILY_FINANCIAL") {
    return {
      ...state,
      dailyFinancial: action.financial,
    };
  } else if (action.type === "GET_WEEKLY_CUTTING") {
    return {
      ...state,
      weeklyCutting: action.cutting,
    };
  } else if (action.type === "GET_MONTHLY_CUTTING") {
    return {
      ...state,
      monthlyCutting: action.cutting,
    };
  } else if (action.type === "GET_MONTHLY_CUTTING_TOTALS") {
    return {
      ...state,
      monthlyTotalsCutting: action.cutting,
    };
  } else if (action.type === "GET_MONTHLY_FINANCIAL") {
    return {
      ...state,
      financialCutting: action.financial,
    };
  } else if (action.type === "GET_MONTHLY_EXPENSES") {
    return {
      ...state,
      expenses: action.expenses,
    };
  } else {
    return state;
  }
};

export default reportReducer;
