const initialState = {
  cuttings: [],
  groups: [],
};

const cuttingReducer = (state = initialState, action) => {
  if (action.type === "SELECT") {
    return {
      ...state,
      cuttings: action.cuttings,
    };
  } else if (action.type === "SORTED") {
    return {
      ...state,
      cuttings: [...action.sortedData],
    };
  } else if (action.type === "DELETE") {
    return {
      ...state,
      cuttings: state.cuttings.filter(
        (item) => item.cuttingid !== action.cuttingId
      ),
    };
  } else if (action.type === "GROUPS") {
    return {
      ...state,
      groups: action.groups,
    };
  } else {
    return state;
  }
};

export default cuttingReducer;
