import React, { useEffect, useState } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CalendarSelector from "../../store/selectors/calendarSelector";
import {
  addCalendar,
  deleteCalendar,
  getCalendar,
} from "../../store/actions/actions";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const Calendar = () => {
  const { t } = useTranslation();
  //redux
  //use selector hook
  const { events } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      events: CalendarSelector.events(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCalendar());
  }, []);

  //modals
  const [showAddModal, setshowAddModal] = useState(false);

  const handleCloseAddModal = () => setshowAddModal(false);
  const handleShowAddModal = () => setshowAddModal(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleshowDeleteModal = () => setShowDeleteModal(true);

  //add event
  const [title, setTitle] = useState();
  const [date, setDate] = useState();

  const handleDateSelect = (selectInfo) => {
    handleShowAddModal(true);
    setDate(selectInfo.startStr);

    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
  };

  const handleAdd = () => {
    dispatch(addCalendar({ title: title, start: date }));
    handleCloseAddModal();
    setTitle("");
  };

  //delete event
  const [id, setId] = useState();

  const handleEventClick = (clickInfo) => {
    handleshowDeleteModal(true);
    setId(clickInfo.event.id);
  };

  const handleDelete = () => {
    dispatch(deleteCalendar(parseInt(id)));
    handleCloseDeleteModal();
  };

  return (
    <div className="calendar">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "today",
        }}
        initialView="dayGridMonth"
        selectable={true}
        selectMirror={true}
        events={events}
        select={handleDateSelect}
        eventClick={handleEventClick}
      />
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("calendarpage.addmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>{t("calendarpage.title1")}:</Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            placeholder={t("placeholders.order")}
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            {t("calendarpage.closebttn")}
          </Button>
          <Button variant="primary" onClick={handleAdd}>
            {t("calendarpage.savebttn")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("calendarpage.deletemodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("calendarpage.deletemessage")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            {t("calendarpage.closebttn")}
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            {t("calendarpage.deletebttn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calendar;
