const initialState = {
  isValid: false,
  userId: 0,
  pages: [],
  roleName: "",
};

const loginReducer = (state = initialState, action) => {
  if (action.type === "LOGIN") {
    return {
      ...state,
      isValid: action.loginData.isValid,
      userId: action.loginData.userId,
      pages: action.loginData.pages,
      roleName: action.loginData.roleName,
    };
  } else if (action.type === "LOGOUT") {
    return {
      ...state,
      isValid: action.isValid,
      userId: 0,
      pages: [],
      roleName: "",
    };
  } else {
    return state;
  }
};

export default loginReducer;
