const initialState = {
  events: [],
};

const calendarReducer = (state = initialState, action) => {
  if (action.type === "GET_EVENTS") {
    return {
      ...state,
      events: action.events,
    };
  } else if (action.type === "DELETE_EVENTS") {
    return {
      ...state,
      events: state.events.filter((item) => item.id !== action.id),
    };
  } else {
    return state;
  }
};

export default calendarReducer;
