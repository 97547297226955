import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

export const EditUser = (props) => {
  const { t } = useTranslation();

  //form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.handleEdit({
        userId: props.row.userid,
        userLName: values.userLName,
        userFName: values.userFName,
        userEmail: values.userEmail,
        userRole: parseInt(dropdownValue),
      });
      event.preventDefault();
    }
    setValidated(false);
  };

  //dropdown
  const [dropdownValue, setDropdownValue] = useState();
  const handleSelect = (e) => {
    setDropdownValue(e.target.value);
  };

  //other values
  const [values, setValues] = useState({
    userLName: "",
    userFName: "",
    userEmail: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    props.row?.userid &&
      setValues({
        userLName: props.row?.lastname,
        userFName: props.row?.firstname,
        userEmail: props.row?.email,
      });
    props.row?.rolename &&
      props.roles &&
      props.roles.forEach((element) => {
        if (element.rolename === props.row?.rolename)
          setDropdownValue(element.roleid);
      });
  }, [props.row]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("userpage.editmodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("userpage.title2")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.firstname")}
                name="userFName"
                value={values.userFName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("userpage.title3")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.lastname")}
                name="userLName"
                value={values.userLName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom02">
              <Form.Label>{t("userpage.title5")}:</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("placeholders.email")}
                name="userEmail"
                value={values.userEmail}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label>{t("userpage.title6")}:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSelect}
                value={dropdownValue}
              >
                <option>{t("userpage.labelselect")}</option>
                {props.roles &&
                  props.roles.map((item, index) => {
                    return (
                      <option key={index} value={item.roleid}>
                        {item.rolename}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Button type="submit">{t("userpage.savebttn")}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUser;
