import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export const DeleteStock = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("statuspage.deletemodal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("statuspage.deletemessage")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            {t("statuspage.closebttn")}
          </Button>
          <Button variant="primary" onClick={props.handleDelete}>
            {t("statuspage.deletebttn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteStock;
