// Use selectors to extract data
const cuttings = (state) => state.cutting.cuttings;
const groups = (state) => state.cutting.groups;
const species = (state) => state.cutting.species;

// You could also create selectors with some logic inside
// const getWhereIAmFrom = (state) => state.cities.allCities.find(c => c.cityName === 'Belgrade');

export default {
  cuttings,
  groups,
  species,
};
