import React, { useEffect, useState } from "react";
import TableBS from "../Table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  sortOrders,
  getOrders,
  deleteOrders,
  addOrders,
  editOrders,
  getSpecies,
} from "../../store/actions/actions";
import OrdersSelector from "../../store/selectors/ordersSelector";
import SpeciesSelector from "../../store/selectors/speciesSelector";
import EditStore, { EditOrder } from "./EditModal";
import DeleteStore, { DeleteOrder } from "./DeleteModal";
import AddStore, { AddOrder } from "./AddModal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import DatePickerWithRange from "../DatePicker";
import { format } from "date-fns";

export const Orders = (props) => {
  const { t } = useTranslation();

  const headers = [
    { title: t("orderspage.title1"), id: "orderid" },
    { title: t("orderspage.title2"), id: "orderdate" },
    { title: t("orderspage.title8"), id: "pickupdate" },
    { title: t("orderspage.title3"), id: "customercode" },
    { title: t("orderspage.title4"), id: "speciesname" },
    { title: t("orderspage.title5"), id: "quantity" },
    { title: t("orderspage.title6"), id: "comment" },
    { title: t("orderspage.title7"), id: "total" },
  ];

  //selected row
  const [row, setRow] = useState({});

  //edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (item) => {
    setRow(item);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);

  //delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleshowDeleteModal = (item) => {
    setRow(item);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  //add modal
  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false);

  //tableData
  const handleTableData = (sortedData) => {
    dispatch(sortOrders(sortedData));
  };

  //redux
  //use selector hook
  const { orders, species } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      orders: OrdersSelector.orders(state),
      species: SpeciesSelector.species(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));

  useEffect(() => {
    dispatch(getOrders(startDate));
    dispatch(getSpecies());
  }, []);

  const getOrdersFromRedux = (startDate) => {
    dispatch(getOrders(startDate));
    setStartDate(startDate);
  };

  const deleteOrdersromRedux = (orderId) => {
    dispatch(deleteOrders(orderId));
    handleCloseDeleteModal();
  };

  const addOrdersFromRedux = (order) => {
    dispatch(addOrders(order, startDate));
    handleCloseAddModal();
  };

  const editOrdersFromRedux = (order) => {
    dispatch(editOrders(order, startDate));
    handleCloseEditModal();
  };

  return (
    <>
      <div className="orders">
        <div className="services_start_page">
          <div className="bttns_inline" onClick={() => setshowAddModal(true)}>
            <Button variant="success" size="lg">
              {t("orderspage.addbttn")}
            </Button>
          </div>
          <DatePickerWithRange
            onChangeDate={(startDate, endDate) => getOrdersFromRedux(startDate)}
            disabled={true}
          />
        </div>
        <TableBS
          headers={headers}
          tableData={orders && orders}
          handleShowEditModal={(item) => handleShowEditModal(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModal(item)}
          sortedData={(sortedData) => handleTableData(sortedData)}
        />
        <EditOrder
          show={showEditModal}
          row={row}
          handleClose={handleCloseEditModal}
          handleEdit={(order) => editOrdersFromRedux(order)}
          species={species}
        />
        <DeleteOrder
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={() => deleteOrdersromRedux(row.orderid)}
        />
        <AddOrder
          show={showAddModal}
          handleClose={handleCloseAddModal}
          handleAdd={(order) => addOrdersFromRedux(order)}
          species={species}
        />
      </div>
    </>
  );
};

export default Orders;
