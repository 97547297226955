const initialState = {
  services: [],
  speciesandgroups: [],
  noofcuts: 0,
};

const serviceReducer = (state = initialState, action) => {
  if (action.type === "GET_SERVICES") {
    return {
      ...state,
      services: action.services,
    };
  } else if (action.type === "SORTED_SERVICES") {
    return {
      ...state,
      services: [...action.sortedData],
    };
  } else if (action.type === "DELETE_SERVICES") {
    return {
      ...state,
      services: state.services.filter(
        (item) => item.serviceid !== action.serviceId
      ),
    };
  } else if (action.type === "GET_SPECIES_GROUPS") {
    return {
      ...state,
      speciesandgroups: action.speciesandgroups,
    };
  } else if (action.type === "GET_CUTS") {
    return {
      ...state,
      noofcuts: action.noofcuts,
    };
  } else {
    return state;
  }
};

export default serviceReducer;
