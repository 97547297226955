import React, { useEffect, useState } from "react";
import DatePickerWithRange from "../DatePicker";
import TableBS from "../Table";
import DeleteCutting from "./DeleteModal";
import EditCutting from "./EditModal";
import Button from "react-bootstrap/Button";
import AddCutting from "./AddModal";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  getCuttings,
  sortTableData,
  deleteCutting,
  addCutting,
  editCutting,
  getGroup,
  getStores,
  getSpecies,
} from "../../store/actions/actions";
import CuttingSelector from "../../store/selectors/cuttingSelectors";
import LoginSelector from "../../store/selectors/loginSelectors";
import SpeciesSelector from "../../store/selectors/speciesSelector";
import { format } from "date-fns";
import CuttingComments from "./CommentModal";
import { useTranslation } from "react-i18next";
import StoreSelector from "../../store/selectors/storeSelector";

export const Cutting = (props) => {
  const { t } = useTranslation();

  const headers = [
    { title: t("cuttingpage.title1"), id: "cuttingid" },
    { title: t("cuttingpage.title2"), id: "cuttingdate" },
    { title: t("cuttingpage.title3"), id: "speciesname" },
    { title: t("cuttingpage.title4"), id: "groupnumber" },
    { title: t("cuttingpage.title5"), id: "noofcuts" },
    { title: t("cuttingpage.title6"), id: "noofparents" },
    { title: t("cuttingpage.title7"), id: "platform" },
    { title: t("cuttingpage.title8"), id: "store" },
    { title: t("cuttingpage.title9"), id: "plantingdate" },
    { title: t("cuttingpage.title10"), id: "averagecuts" },
    { title: t("cuttingpage.title11"), id: "commenttext" },
    { title: t("cuttingpage.title12"), id: "treatment" },
  ];

  //selected row
  const [row, setRow] = useState({});

  //edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const handleShowEditModal = (item) => {
    setRow(item);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);

  //delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleshowDeleteModal = (item) => {
    setRow(item);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  //add modal
  const [showAddModal, setshowAddModal] = useState(false);
  const handleCloseAddModal = () => setshowAddModal(false);

  //comment modal
  const [showCommentModal, setShowCommentModal] = useState(false);
  const handleCloseCommentModal = () => setShowCommentModal(false);

  //tableData
  const handleTableData = (sortedData) => {
    dispatch(sortTableData(sortedData));
  };

  //redux
  //use selector hook
  const { cuttings, species, roleName, stores } = useSelector(
    (state) => ({
      // Create your custom selectors to extract data
      cuttings: CuttingSelector.cuttings(state),
      species: SpeciesSelector.species(state),
      roleName: LoginSelector.roleName(state),
      stores: StoreSelector.stores(state),
    }),
    // Always pass the shallowEqual function at least, it can help with the performance
    shallowEqual
  );
  // use useDispatch hook to retrieve dispatch function...
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

  useEffect(() => {
    dispatch(getCuttings(startDate, endDate));
    dispatch(getSpecies());
    dispatch(getStores());
  }, []);

  // ...and than map your thunks bellow
  const getCuttingsFromRedux = (startDate, endDate) => {
    dispatch(getCuttings(startDate, endDate));
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const deleteCuttingsFromRedux = (cuttingId) => {
    dispatch(deleteCutting(cuttingId));
    handleCloseDeleteModal();
  };

  const addCuttingsFromRedux = (cutting) => {
    dispatch(addCutting(cutting, startDate, endDate));
    handleCloseAddModal();
  };

  const editCuttingsFromRedux = (cutting) => {
    dispatch(editCutting(cutting, startDate, endDate));
    handleCloseEditModal();
  };

  return (
    <>
      <div className="cutting">
        <div className="cutting_start_page">
          <div className="bttns_inline" onClick={() => setshowAddModal(true)}>
            <Button variant="success" size="lg">
              {t("cuttingpage.addbttn")}
            </Button>
          </div>
          <DatePickerWithRange
            onChangeDate={(startDate, endDate) =>
              getCuttingsFromRedux(startDate, endDate)
            }
          />
        </div>
        <TableBS
          headers={headers}
          tableData={cuttings && cuttings}
          handleShowEditModal={(item) => handleShowEditModal(item)}
          handleshowDeleteModal={(item) => handleshowDeleteModal(item)}
          sortedData={(sortedData) => handleTableData(sortedData)}
        />
        {/* {roleName && roleName === "Administrator" && (
          <div className="cutting_comments">
            <i
              className="fas fa-comments fa-4x"
              onClick={() => setShowCommentModal(true)}
            ></i>
          </div>
        )} */}
        <EditCutting
          show={showEditModal}
          row={row}
          handleClose={handleCloseEditModal}
          handleEdit={(cutting) => editCuttingsFromRedux(cutting)}
          species={species}
          stores={stores}
        />
        <DeleteCutting
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleDelete={() => deleteCuttingsFromRedux(row.cuttingid)}
        />
        <AddCutting
          show={showAddModal}
          handleClose={handleCloseAddModal}
          handleAdd={(cutting) => addCuttingsFromRedux(cutting)}
          species={species}
          stores={stores}
        />
        {/* <CuttingComments
          show={showCommentModal}
          handleClose={handleCloseCommentModal}
        /> */}
      </div>
    </>
  );
};

export default Cutting;
